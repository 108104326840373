import { MailruIcon } from "react-share";
// const authUrlOrigin='http://localhost/aamusted_auth/';

var domainSettings = {
    dbName: 'postitonline',
    dbVersion: 1,
    dbTables: [{
        tblName: 'user',
        tblOpt: { autoIncrement: true },
        tblIndexes: [{ indexName: 'user', indexOpt: { unique: true } }]
    },
    ],
    //P73/V PENKWASE, BS-0080-6744 
    appName: 'Postitonlinegh',
    appMail: 'support@postitonlinegh.com',
    numPopularToAppearOnCategoryTop: 5,
    googleClientUrl: 'https://accounts.google.com/gsi/client',
    googleClientID: '720696234161-050k7elpoios13i2ocfpu7el28k4ae5v.apps.googleusercontent.com',
    facebookAppID: 597121861801078,
    primaryColor: 'pinkx darken-4x special-color-darkx brownx darken-4x teal darken-4',
    secondaryColor: 'pinkx darken-1x special-colorx brownx darken-1x deep-turquiose',
    textColor: 'blue-grey-text',
    primaryColorHex: '#004d40',
    secondaryColorHex: '#1e847f',
    textColorHex: '#607d8b',

    backend:`https://${window.location.host}/api`,
    root:`https://${window.location.host}`,

    // backend: 'https://finance.aamusted.edu.gh/api',
    // root: 'https://finance.aamusted.edu.gh',

    // backend: 'http://192.168.8.109/aamusted_finance/api',
    // logo: 'http://192.168.8.109/aamusted_finance',


}

export default domainSettings;


