
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Divider, Tag, message, Select } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDrawer from '../../hooks/drawer';
import { confirmAlert } from 'react-confirm-alert';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import useExcel from '../../hooks/excel';
const Transactions = (props) => {
    const valuesStore = ValuesStore();
    const meta = 'other_metadata';
    // const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd(meta, 'table_name');
    // const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const [queryLoading,setQueryLoading] = useState(false);
    const plainOptions = ['TxnID', 'Firstname', 'Surname', 'Middlename', 'Sex', 'DOB', 'AmtPaid', 'Cum',
        'Type', 'AcadYr', 'Bank', 'IndexNo', 'PayItem', 'Program', 'Session', 'StudentType', 'Level',
        'Campus', 'Faculty', 'Department', 'Date', 'Description', 'Teller', 'Flow', 'Reversal','AppID'];
    const colOptions = plainOptions.map(v => ({ label: v, value: v }));
    const [columns, setColumns] = useState([]);
    const drawer = useDrawer();
    const excel = useExcel();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'txn', fields: ['*'] });

    const detailedOption = {
        TxnID: {
            title: 'TxnID',
            dataIndex: 'bank_txn_id'
        },
        Firstname: {
            title: 'Firstname',
            dataIndex: 'fname'
        },
        Surname: {
            title: 'Lastname',
            dataIndex: 'lname'
        },
        Middlename: {
            title: 'Middlename',
            dataIndex: 'mname'
        },
        Sex: {
            title: 'Sex',
            dataIndex: 'sex'
        },
        DOB: {
            title: 'DOB',
            dataIndex: 'dob',
            render: (v, record) => {
                return new Date(utils.formatDate(v)).toLocaleDateString();
            }
        },
        AmtPaid: {
            title: 'AmtPaid',
            dataIndex: 'amt_paid'
        },
        Cum: {
            title: 'Cum',
            dataIndex: 'cum'
        },
        Level: {
            title: 'Level',
            dataIndex: 'level',
            ...table.getColumnSearchProps('level')
        },
        AcadYr: {
            title: 'AcadYr',
            dataIndex: 'acad_year',
            // filterSearch: true,
            ...table.getColumnSearchProps('level')
        },
        Bank: {
            title: 'Bank',
            // filterSearch: true,
            dataIndex: 'bank_code',
        },
        IndexNo: {
            title: 'IndexNo',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        Type: {
            title: 'Type',
            dataIndex: 'debit_credit',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        PayItem: {
            title: 'PayItem',
            // filterSearch: true,
            dataIndex: 'pay_item',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        Session: {
            title: 'Session',
            dataIndex: 'session'
        },
        StudentType: {
            title: 'StudentType',
            dataIndex: 'student_type'
        },
        Campus: {
            title: 'Campus',
            dataIndex: 'campus'
        },
        Faculty: {
            title: 'Faculty',
            dataIndex: 'faculty_id'
        },
        Department: {
            title: 'Department',
            dataIndex: 'dept_id'
        },
        Date: {
            title: 'Date',
            dataIndex: 'date_inserted',
            render: (v, record) => {
                return new Date(utils.formatDate(v)).toLocaleDateString();
            }
        },
        Program: {
            title: 'Program',
            // filterSearch: true,
            dataIndex: 'name',
        },
        Description: {
            title: 'Description',
            // filterSearch: true,
            dataIndex: 'description',
        },
        Teller: {
            title: 'Teller',
            // filterSearch: true,
            dataIndex: 'teller',
        },
        Reversal: {
            title: 'Reversal',
            dataIndex: 'is_reversal',
            render: (v, record) => {
                return v ? 'Yes' : 'No';
            }
        },
        Flow: {
            title: 'Flow',
            // filterSearch: true,
            dataIndex: 'debit_credit',
        }, 
        AppID: {
            title: 'AppID',
            // filterSearch: true,
            dataIndex: 'app_id',
        }
    };

    function resetTable() {
        navigate(0);
    }



    async function onReverse(value) {
        confirmAlert({
            title: 'Confim reversal',
            message: 'Continuing will reverse this transaction. Click on Yes to continue. Click on No to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { reverseTransaction(value) }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    async function reverseTransaction(value) {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/reverse_txn_to_manual_txn`, null, value);
        if (res.status == 'Ok') {
            message.success('Transaction has been reversed successfully');
        } else {
            message.error(res.msg);
        }
    }


   
    function genFilters() {
        setTimeout(() => {
            valuesStore.setValue(meta, [
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'app_id', col_real_name: 'App.ID', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'index_no', col_real_name: 'IndexNo', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'acad_year', col_real_name: 'AcadYr', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'bank_txn_id', col_real_name: 'TxnID', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'bank_code', col_real_name: 'Bank', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT bank_code,bank_name from bank_accounts","key":"bank_code","value":"bank_name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'pay_item', col_real_name: 'PayItem', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT item,alias from pay_items","key":"alias","value":"item"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'program', col_real_name: 'Program', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from programs","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'session', col_real_name: 'Session', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT session,alias from sessions","key":"alias","value":"session"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'student_type', col_real_name: 'Student Type', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT type from student_type","key":"type","value":"type"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'current_level', col_real_name: 'Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level from level","key":"level","value":"level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'campus', col_real_name: 'Campus', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 2","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'faculty_id', col_real_name: 'Faculty', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 4","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'dept_id', col_real_name: 'Department', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 5","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                }
            ]);
            add.setTblName('txn_filters');
        }, 1000);
    }

    function exportXLS() {
        const headings = [];
        const dataIndexes = [];
        columns.forEach(v => {
            headings.push(v.title);
            dataIndexes.push(v.dataIndex);
        });

        const data = table?.data?.map(v => {
            let obj = {};
            dataIndexes.forEach(key => {
                obj[key] = v[key];
            });
            return obj;
        });

        excel.exportXLSX(headings, data, 'sheet1', `transaction_${new Date()}.xlsx`);
    }

    useMemo(() => {
        genFilters();
        console.log('looping');        
    }, [add.saveCompleted, table.extraFetchParams]);

    async function query() {
        setQueryLoading(true);
        const range = utils.getDateFromMoment(dateRange);
        let rng = null;
        if (range) {
            const { startDate, endDate } = range;
            rng = { startDate, endDate }
        }
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_report`, null, { filters: add.record, date_range: rng });
        
        table.setData(res['result']);
        table.setColumns(columns);

        if (columns.length) {
            table.setColumns(columns);
        } else {
            const preset = [
                { dataIndex: "bank_txn_id", title: "ID" },
                { dataIndex: "bank_code", title: "Bank" },
                { dataIndex: "fname", title: "Firstname" },
                { dataIndex: "lname", title: "Surname" },
                { dataIndex: "mname", title: "Middlename" },
                { dataIndex: "index_no", title: "IndexNo." },
                { dataIndex: "app_id", title: "AppID" },
                { dataIndex: "sex", title: "Sex" },
                { dataIndex: "amt_paid", title: "AmtPaid" },
                // { dataIndex: "cum", title: "Cummulative" },
                { dataIndex: "pay_item", title: "PayItem" },

                // {
                //     title: 'Reversal',
                //     dataIndex: 'is_reversal',
                //     render: (v, record) => {
                //         return v ? 'Yes' : 'No';
                //     }
                // },
                {
                    title: 'Flow',
                    dataIndex: 'debit_credit',
                },
                {
                    dataIndex: "date_inserted", title: "Date", render: (v, record) => {
                        return new Date(utils.formatDate(v)).toLocaleDateString();
                    }
                }
            ];
            table.setColumns(preset);
            setColumns(preset);
        }
        setQueryLoading(false);
    }

    function onColumnAdded(list) {
        let cols = [];
        list.forEach(v => {
            const info = detailedOption[v];
            cols.push(info);
        });
        cols.push({
            title: 'View',
            key: 'view',
            render: (value, record) => {
                return <Button className='btn-primary border-0' onClick={e => CustomFunctions.viewTxn(value, drawer, valuesStore, Tag, Divider, Space, true)}><i className='fas fa-eye' /></Button>
            }
        }, {
            title: 'Reverse',
            key: 'reverse',
            render: (value, record) => {
                return <Button className='btn-warning border-0' onClick={e => onReverse(value)}><i className='fas fa-undo' /></Button>
            }
        });
        setColumns(cols);
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12'>
                        <Divider orientation='left'>Columns</Divider>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            filterOption
                            placeholder="Please select your desired columns"
                            onChange={onColumnAdded}
                            options={colOptions}
                        />
                    </div>
                    <div className='col-md-12'>
                        <Divider orientation='left'>Filters</Divider>
                        <div className='row row-cols-2'>
                            {add.form?.map((v, i) => {
                                return <div key={i} className='col'>
                                    {v}
                                </div>
                            })}
                        </div>

                        <div className='row rounded'>
                            <div className='col-md-12 mb-2'>
                                <DatePicker.RangePicker className='w-100' onChange={v => setDateRange(v)} value={dateRange} />
                            </div>
                            <div className='col-md-12'>
                                <Space className=''>
                                    <Button loading={queryLoading} className='btn-success border-0' onClick={e => query()}><i className='fas fa-filter me-2' />Query</Button>
                                    <Button className='btn-danger border-0' onClick={e => resetTable()}><i className='fas fa-filter me-2' /> Clear All Filters</Button>
                                    <Button className='btn-warning border-0' onClick={e => exportXLS()}><i className='fas fa-file me-2' /> Excel</Button>
                                </Space>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-12 mb-2'>

                    </div> */}
                    <div className='col-md-12 mt-1'>
                        {table.table}
                    </div>
                </div>
            </div>                        
            {drawer.drawerJSX()}
        </>
    );
}

export default memo(Transactions);