
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Divider, Select, Drawer, List } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
// import useDrawer from '../../hooks/drawer';
import { confirmAlert } from 'react-confirm-alert';
import CustomFunctions from '../../dependencies/custom/custom-functions';

import useExcel from '../../hooks/excel';

const Transactions = (props) => {
    const valuesStore = ValuesStore();
    const meta = 'other_metadata';
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd(meta, 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const excel = useExcel();
    const [filterDrawerOpen, setFilterDrawerOpen] = useState();
    const [queryLoading, setQueryLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [errors, setErrors] = useState([]);
    const keyOverrides = { categoryAlias: 'category' };
    const plainOptions = ['TxnID', 'Firstname', 'Surname', 'Middlename', 'Sex', 'DOB',
        'AmtPaid', 'Cum', 'Flow', 'AcadYr', 'Bank', 'IndexNo', 'PayItem', 'Program',
        'Session', 'StudentType', 'Level', 'Campus', 'Faculty', 'Department', 'Description',
        'TopazCode', 'TopazDescription', 'Debit', 'Credit', 'TopazDate', 'Date'];
    const colOptions = plainOptions.map(v => ({ label: v, value: v }));

    const [columns, setColumns] = useState([]);
    // const drawer = useDrawer();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 100,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'txn', fields: ['*'] });

    const summaryTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 50,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'id',
        {},
        {});

    const summaryTableColumns = ([
        {
            title: 'Item',
            dataIndex: 'item',
            ...table.getColumnSearchProps('item'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            ...table.getColumnSearchProps('amount'),
        }
    ]);

    const detailedOption = {
        TxnID: {
            title: 'TxnID',
            dataIndex: 'bank_txn_id'
        },
        Firstname: {
            title: 'Firstname',
            dataIndex: 'fname'
        },
        Surname: {
            title: 'Lastname',
            dataIndex: 'lname'
        },
        Middlename: {
            title: 'Middlename',
            dataIndex: 'mname'
        },
        Sex: {
            title: 'Sex',
            dataIndex: 'sex'
        },
        DOB: {
            title: 'DOB',
            dataIndex: 'dob',
            render: (v, record) => {
                return new Date(utils.formatDate(v)).toLocaleDateString();
            }
        },
        AmtPaid: {
            title: 'AmtPaid',
            dataIndex: 'amt_paid'
        },
        Cum: {
            title: 'Cum',
            dataIndex: 'cum'
        },
        Level: {
            title: 'Level',
            dataIndex: 'level',
            ...table.getColumnSearchProps('level')
        },
        AcadYr: {
            title: 'AcadYr',
            dataIndex: 'acad_year',
            // filterSearch: true,
            ...table.getColumnSearchProps('level')
        },
        Bank: {
            title: 'Bank',
            // filterSearch: true,
            dataIndex: 'bank_code',
        },
        IndexNo: {
            title: 'IndexNo',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        Flow: {
            title: 'Flow',
            dataIndex: 'debit_credit',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        PayItem: {
            title: 'PayItem',
            // filterSearch: true,
            dataIndex: 'pay_item',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        Session: {
            title: 'Session',
            dataIndex: 'session'
        },
        StudentType: {
            title: 'StudentType',
            dataIndex: 'student_type'
        },
        Campus: {
            title: 'Campus',
            dataIndex: 'campus'
        },
        Faculty: {
            title: 'Faculty',
            dataIndex: 'faculty_id'
        },
        Department: {
            title: 'Department',
            dataIndex: 'dept_id'
        },
        Program: {
            title: 'Program',
            // filterSearch: true,
            dataIndex: 'name',
        },
        Description: {
            title: 'Description',
            dataIndex: 'description',
        },
        TopazCode: {
            title: 'aid',
            dataIndex: 'aid',
        },
        TopazDescription: {
            title: 'desc',
            dataIndex: 'topaz_desc',
        },
        Debit: {
            title: 'dr',
            dataIndex: 'dr',
        },
        Credit: {
            title: 'cr',
            dataIndex: 'cr',
        },
        Date: {
            title: 'date',
            dataIndex: 'date_inserted',
            // render: (v, record) => {
            //     return new Date(utils.formatDate(v)).toLocaleDateString();
            // }
        },
        TopazDate: {
            title: 'date',
            dataIndex: 'txn_date',
            render: (v, record) => {
                // const t = new Date(utils.formatDate(v));
                const t = utils.formatDate(v)?.split('-');
                const day = t[2];
                const month = t[1];
                const year = t[0];
                return `${month}/${day}/${year}`;
            }
        }

    };

    function resetTable() {
        navigate(0);
    }


    function onColumnAdded(list) {
        let cols = [];
        list.forEach(v => {
            const info = detailedOption[v];
            cols.push(info);
        });
        setColumns(cols);
    }



    function genFilters() {
        setTimeout(() => {
            valuesStore.setValue(meta, [
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'index_no', col_real_name: 'IndexNo', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'acad_year', col_real_name: 'AcadYr', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'bank_txn_id', col_real_name: 'TxnID', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'bank_code', col_real_name: 'Bank', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT bank_code,bank_name from bank_accounts","key":"bank_code","value":"bank_name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'pay_item', col_real_name: 'PayItem', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT item,alias from pay_items","key":"alias","value":"item"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'program', col_real_name: 'Program', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from programs","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'session', col_real_name: 'Session', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT session,alias from sessions","key":"alias","value":"session"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'student_type', col_real_name: 'Student Type', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT type from student_type","key":"type","value":"type"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'current_level', col_real_name: 'Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level from level","key":"level","value":"level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'campus', col_real_name: 'Campus', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 2","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'faculty_id', col_real_name: 'Faculty', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 4","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'dept_id', col_real_name: 'Department', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 5","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                }
            ]);
            add.setTblName('txn_filters');
        }, 1000);
    }


    useMemo(() => {
        genFilters();
        summaryTable.setColumns(summaryTableColumns);
        // table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping');
        // table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);

    async function query() {
        setQueryLoading(true);
        const range = utils.getDateFromMoment(dateRange);
        let rng = null;
        if (range) {
            const { startDate, endDate } = range;
            rng = { startDate, endDate }
        }
        let response = await utils.requestWithReauth('post', `${Settings.backend}/get_topaz_report`, null, { filters: add.record, date_range: rng });

        let result = response.report;
        let errors = response.errors;

        let errs = {};
        errors?.forEach((value, index) => {
            const prg = valuesStore.getArrayObjectsValue('programs', 'alias', value.program)?.name;
            errs[value.bill_key] = <div className='' key={index}><label>No bill components for {value.bill_key}-{prg}</label><br /></div>;
        });

        let errLabels = [];
        for (let k in errs) {
            errLabels.push(errs[k]);
        }
        setErrors(errLabels);

        setResult(response);
        let totalBankPayments = 0;
        let components = {};
        const cmp = valuesStore.getValue('student_bill_components');
        cmp?.forEach(v => {
            components[v.component_name] = 0;
        });

        let payments = [];//payments to the dr side. breakdowns to the cr side for normal transactions. //payments to the cr side. breakdowns to the dr side for reversed transactions.         
        result?.forEach((record, ind1) => {
            const details = record?.details;
            delete record['details'];
            // console.log(record);
            if (/*record.is_reversal &&*/record.debit_credit == 'dr') {
                totalBankPayments -= parseFloat(record?.amt_paid);
                const topaz_desc = `Pmt of ${record.pay_item} by ${record.fname} ${record.mname} ${record.lname} ${record.index_no} - ${record.applicant_id} prog - ${record.name} - ${record.current_level} - ${record.level}`;
                const data = { ...record, cr: record.amt_paid, dr: 0, aid: record.bank_acct, topaz_desc, id: utils.generateUuid() };
                payments.push(data);
                for (let key in details) {
                    const topaz_desc2 = `Pmt of ${key} by ${record.fname} ${record.mname} ${record.lname} ${record.index_no} - ${record.applicant_id} prog - ${record.name} - ${record.current_level} - ${record.level}`;
                    const data2 = { ...record, cr: 0, dr: details[key].allocated, aid: details[key].code, topaz_desc: topaz_desc2, id: utils.generateUuid() };
                    payments.push(data2);
                    components[key] -= parseFloat(details[key].allocated);
                }
            } else {
                totalBankPayments += parseFloat(record?.amt_paid);
                const topaz_desc = `Pmt of ${record.pay_item} by ${record.fname} ${record.mname} ${record.lname} ${record.index_no} - ${record.applicant_id} prog - ${record.name} - ${record.current_level} - ${record.level}`;
                const data = { ...record, dr: record.amt_paid, cr: 0, aid: record.bank_acct, topaz_desc, id: utils.generateUuid() };
                payments.push(data);
                for (let key in details) {
                    const topaz_desc2 = `Pmt of ${key} by ${record.fname} ${record.mname} ${record.lname} ${record.index_no} - ${record.applicant_id} prog - ${record.name} - ${record.current_level} - ${record.level}`;
                    const data2 = { ...record, dr: 0, cr: details[key].allocated, aid: details[key].code, topaz_desc: topaz_desc2, id: utils.generateUuid() };
                    payments.push(data2);
                    components[key] += parseFloat(details[key].allocated);
                }
            }
        });
        let totalSum = 0;
        let a = [];
        let inc = 1;
        const sortable = Object.fromEntries(Object.entries(components).sort(([, a], [, b]) => b.priority - a.priority));
        for (let k in sortable) {
            totalSum += components[k];
            a.push({ item: <label className='fw-bold'>{k}</label>, amount: <label className='text-primary'>{components[k]?.toFixed(2)}</label>, id: inc });
            inc++;
        }
        a.unshift(
            { item: <label className='fw-bold'>Bank Approx.</label>, amount: <label className='text-primary'> {totalBankPayments?.toFixed(2)}</label>, id: 0 },
            { item: <label className='fw-bold'>Total Comp. Approx.</label>, amount: <label className='text-primary'> {totalSum?.toFixed(2)}</label>, id: a.length + 2 },
            { item: <label className='fw-bold'>Difference. Approx.</label>, amount: <label className='text-primary'> {totalBankPayments?.toFixed(2) - totalSum?.toFixed(2)}</label>, id: a.length + 3 },
        );
        summaryTable.setData(a);
        table.setData(payments);
        table.setColumns(columns);

        if (columns.length) {
            table.setColumns(columns);
        } else {
            const preset = [
                { dataIndex: "aid", title: "aid" },
                { dataIndex: "topaz_desc", title: "desc" },
                { dataIndex: "dr", title: "dr" },
                { dataIndex: "cr", title: "cr" },
                {
                    dataIndex: "txn_date", title: "date", render: (v, record) => {
                        // return new Date(utils.formatDate(v)).toLocaleDateString();
                        const t = utils.formatDate(v)?.split('-');
                        const day = t[2];
                        const month = t[1];
                        const year = t[0];
                        return `${month}/${day}/${year}`;
                    }
                }
            ];
            table.setColumns(preset);
            setColumns(preset);
        }
        setFilterDrawerOpen(false);
        setQueryLoading(false);
    }

    function exportXLS(ext) {
        const headings = [];
        const dataIndexes = [];
        columns.forEach(v => {
            headings.push(v.title);
            dataIndexes.push(v.dataIndex);
        });
        const data = table?.data?.map(v => {
            let obj = {};
            dataIndexes.forEach(key => {
                if (key == 'txn_date') {
                    // obj[key] = new Date(utils.formatDate(v[key])).toLocaleDateString();
                    const t = utils.formatDate(v[key])?.split('-');
                    const day = t[2];
                    const month = t[1];
                    const year = t[0];
                    obj[key]  = `${month}/${day}/${year}`;
                } else {
                    obj[key] = v[key];
                }
            });
            return obj;
        });
        excel.exportXLSX(headings, data, 'sheet1', `topaz_report_${new Date()}.${ext}`);
    }

    function caption() {
        return <label className='fw-bold text-primary'>Total Row Count:{result?.report?.length}</label>
    }

    return (
        <>
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12'>
                        <Divider orientation='left'>Columns</Divider>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            filterOption
                            placeholder="Please select your desired columns"
                            onChange={onColumnAdded}
                            options={colOptions}
                        />
                        {/* <Divider orientation='left'>Summaries</Divider> */}
                        <div className='row row-cols-3 g-2 mt-1'>
                            <div className='col p-2 h-scrolling-wrapper' style={{ height: '300px', overflowY: 'auto' }}>
                                {summaryTable.table}
                            </div>
                            <div className='col p-2 h-scrolling-wrapper bg-whitex' style={{ height: '300px', overflowY: 'auto' }}>
                                <List
                                    className='bg-white'
                                    size="small"
                                    header={<div className='text-danger fw-bold'>{errors?.length} Errors Summaries</div>}
                                    bordered
                                    dataSource={errors}
                                    renderItem={(item) => <List.Item>{item}</List.Item>
                                    }
                                />
                            </div>
                            <div className='col p-2 h-scrolling-wrapper bg-whitex' style={{ height: '300px', overflowY: 'auto' }}>
                                <List
                                    className='bg-white'
                                    size="small"
                                    header={<div className='text-danger fw-bold'>{result?.errors?.length && (result?.errors.length)} Transaction Errors</div>}
                                    bordered
                                    dataSource={result?.errors}
                                    renderItem={(item) => <List.Item>{item.msg}-{valuesStore.getArrayObjectsValue('programs', 'alias', item.program)?.name}</List.Item>}
                                />
                            </div>
                        </div>

                        <div className='mt-2'>
                            <DatePicker.RangePicker className='w-100' onChange={v => setDateRange(v)} value={dateRange} />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row rounded'>
                            <div className='col-md-12 mt-3'>
                                <Space className=''>
                                    <Button loading={queryLoading} className='btn-success border-0' onClick={e => query()}><i className='fas fa-filter me-2' />Query</Button>
                                    <Button className='btn-danger border-0' onClick={e => resetTable()}><i className='fas fa-filter me-2' /> Clear All Filters</Button>
                                    <Button className='btn-warning border-0' onClick={e => exportXLS('xlsx')}><i className='fas fa-file me-2' /> Excel</Button>
                                    <Button className='btn-primary border-0' onClick={e => exportXLS('csv')}><i className='fas fa-file me-2' /> CSV</Button>
                                    <Button onClick={e => setFilterDrawerOpen(true)} className='btn-primary border-0'> <i className='me-2 fas fa-filter' /> Apply More Filters</Button>
                                </Space>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 mt-3'>
                        {table.tableWithHeaderFooter(caption, caption)}
                    </div>
                </div>
            </div>


            <Drawer zIndex={1020} width={'100%'} title="Filters" placement="right" onClose={e => setFilterDrawerOpen(false)} open={filterDrawerOpen}>
                <div className='row row-cols-2'>
                    {add.form?.map((v, i) => {
                        return <div key={i} className='col'>
                            {v}
                        </div>
                    })}
                    <div className='col-md-12 mb-2'>
                        <DatePicker.RangePicker className='w-100' onChange={v => setDateRange(v)} value={dateRange} />
                    </div>
                    <div className='col'>
                        <Space className=''>
                            <Button loading={queryLoading} className='btn-success border-0' onClick={e => query()}><i className='fas fa-filter me-2' />Query</Button>
                            <Button className='btn-danger border-0' onClick={e => resetTable()}><i className='fas fa-filter me-2' /> Clear All Filters</Button>
                        </Space>
                    </div>
                </div>
            </Drawer>
            {/* {drawer.drawerJSX()} */}
        </>
    );
}

export default memo(Transactions);