
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, message } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const BankAccounts = (props) => {
    const valuesStore = ValuesStore();
    // const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const edit = useEdit('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    const [modalTitle, setModalTitle] = useState('Add New Item');
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        // `${Settings.backend}/get_data`,
        `${Settings.backend}/get_program_session_codes`,
        'post',
        'result',
        'totalCount',
        'id',
        { 'program_session_code.program': 'IN', 'session': 'IN' },
        { table: 'program_session_code', fields: ['*'] });


    const columns = ([

        {
            title: 'Program',
            dataIndex: 'name',
            filterSearch: true
        },
        {
            title: 'Session',
            dataIndex: 'session',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            ...table.getColumnSearchProps('code'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => editRecord(record, 'program_session_code')}><i className='fas fa-edit text-successx' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'program_session_code', where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0'><i className='fas fa-trash text-dangerx' /></Button>
                    )}
                </Space>
            },
        },
    ]);

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'program_session_code',
            { tbl: 'program_session_code', where: 'id', whereType: 'closed' },
        );
    }

    const colFilters = [
        {
            filter: 'session',
            sql: "SELECT session,alias FROM sessions WHERE is_active ='1' ",
            key: 'alias',
            value: 'session'
        },
        {
            filter: 'name',
            sql: "SELECT name,alias FROM programs WHERE show_hide ='1' ",
            key: 'alias',
            value: 'name'
        }
    ];

    async function editRecord(record, tableName) {
        // utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'program_session_code') {
        setModalTitle('Add New Item');
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }


    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'program_session_code' })
        if (res.status == 'Ok') {
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
    }


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted,edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                        </Space>
                    </div>
                    <div className='col-md-8'>
                        {table.table}
                    </div>
                </div>
            </div>
            {add.addModal(modalTitle, addOnOk)}
            {edit.editModal('Edit Item', editOnOk)}
        </>
    );
}

export default memo(BankAccounts);