
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, List } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useExcel from '../../hooks/excel';


const BreakdownReport = (props) => {
    const valuesStore = ValuesStore();
    const queryItemsMeta = 'queryItemsMeta';
    const filterItemsMeta = 'filterItemsMeta';
    const add = useAdd(queryItemsMeta, 'table_name');
    const filterAdd = useAdd(filterItemsMeta, 'table_name');
    const [errors, setErrors] = useState();
    const [result, setResult] = useState();
    const [queryLoading, setQueryLoading] = useState(false);
    const { filters, filterTypes } = utils.generateTableFilters();
    const excel = useExcel();

    const navigate = useNavigate();

    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        {},
        {});

    const summaryTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 50,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'id',
        {},
        {});

    const reportTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 50,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'sn',
        {},
        {});
    // { sn: (i + 1), txn_id: v.bank_txn_id, acadyr: add?.record?.acad_yr, component: add?.record?.component, index: v.index_no, program: prg, amount: details[add?.record?.component]?.allocated }
    const reportTableColumns = ([
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Txn.ID',
            dataIndex: 'txn_id',
            ...table.getColumnSearchProps('txn_id'),
        },
        {
            title: 'Acad.Yr.',
            dataIndex: 'acadyr',
        },
        {
            title: 'Component',
            dataIndex: 'component',
        },
        {
            title: 'IndexNo.',
            dataIndex: 'index',
            ...table.getColumnSearchProps('index'),
        },
        {
            title: 'Program',
            dataIndex: 'program',
            ...table.getColumnSearchProps('program'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        }
    ]);

    const summaryTableColumns = ([
        {
            title: 'Item',
            dataIndex: 'item',
            // ...table.getColumnSearchProps('item'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            // ...table.getColumnSearchProps('amount'),
        }
    ]);

    // const columns = ([
    //     {
    //         title: 'Component',
    //         dataIndex: 'component',
    //         ...table.getColumnSearchProps('component'),
    //     },
    //     {
    //         title: 'Amount(GHC)',
    //         dataIndex: 'amount',
    //         ...table.getColumnSearchProps('amount'),
    //         sorter: {
    //             compare: (a, b) => a.amount - b.amount,
    //         }
    //     },
    //     {
    //         title: 'Priority',
    //         dataIndex: 'priority',
    //         ...table.getColumnSearchProps('priority'),
    //         sorter: {
    //             compare: (a, b) => a.priority - b.priority,
    //         },
    //     },
    // ]);


    function filterItems() {
        setTimeout(e => {
            valuesStore.setValue(filterItemsMeta, [
                {
                    id: '', table_name: 'filter_items',
                    column_name: 'bank_code', col_real_name: 'Bank', type: 'sqlSelect',
                    options: '{"sql":"SELECT bank_name,bank_code FROM bank_accounts","key":"bank_code","value":"bank_name"}',
                    backend_visible: 1, editable: 1, rank: 1
                },
                {
                    id: '', table_name: 'filter_items',
                    column_name: 'program', col_real_name: 'Program', type: 'sqlSelect',
                    options: '{"sql":"SELECT name,alias FROM programs","key":"alias","value":"name"}',
                    backend_visible: 1, editable: 1, rank: 1
                },
                {
                    id: '', table_name: 'filter_items',
                    column_name: 'student_type', col_real_name: 'Student Type', type: 'sqlSelect',
                    options: '{"sql":"SELECT type FROM student_type","key":"type","value":"type"}',
                    backend_visible: 1, editable: 1, rank: 1
                },
                {
                    id: '', table_name: 'filter_items',
                    column_name: 'campus', col_real_name: 'Campus', type: 'sqlSelect',
                    options: '{"sql":"SELECT name,alias FROM organizational_type WHERE type = 2","key":"alias","value":"name"}',
                    backend_visible: 1, editable: 1, rank: 1
                },
                {
                    id: '', table_name: 'filter_items',
                    column_name: 'dept_id', col_real_name: 'Department', type: 'sqlSelect',
                    options: '{"sql":"SELECT name,alias FROM organizational_type WHERE type = 5","key":"alias","value":"name"}',
                    backend_visible: 1, editable: 1, rank: 1
                },
                {
                    id: '', table_name: 'filter_items',
                    column_name: 'faculty_id', col_real_name: 'Faculty', type: 'sqlSelect',
                    options: '{"sql":"SELECT name,alias FROM organizational_type WHERE type = 4","key":"alias","value":"name"}',
                    backend_visible: 1, editable: 1, rank: 1
                },
            ]);
            filterAdd.setTblName('filter_items');
        }, 1000);
    }



    function queryItems() {
        setTimeout(e => {
            valuesStore.setValue(queryItemsMeta, [
                {
                    id: '', table_name: 'query_items',
                    column_name: 'acad_yr', col_real_name: 'Academic Year', type: 'text',
                    backend_visible: 1, editable: 1, icon: 'fas fa-user', rank: 1
                },
                {
                    id: '', table_name: 'query_items',
                    column_name: 'level', col_real_name: 'Level', type: 'sqlSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1, editable: 1, rank: 1
                },
                {
                    id: '', table_name: 'query_items',
                    column_name: 'component', col_real_name: 'Component', type: 'sqlSelect',
                    options: '{"sql":"SELECT component_name FROM student_bill_components","key":"component_name","value":"component_name"}',
                    backend_visible: 1, editable: 1, rank: 1
                }
            ]);
            add.setTblName('query_items');
        }, 1000);
    }

    useMemo(() => {
        queryItems();
        filterItems();
        // table.setColumns(columns);
        reportTable.setColumns(reportTableColumns);
        summaryTable.setColumns(summaryTableColumns);
        console.log('looping')

    }, [table.extraFetchParams]);

    async function query() {
        setQueryLoading(true);
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_txn_priority_breakdown`, null, add.record);
        const response = res.result;
        let result = response.report;
        let errors = response.errors;

        let errs = {};
        errors?.forEach((value, index) => {
            const prg = valuesStore.getArrayObjectsValue('programs', 'alias', value.program)?.name;
            errs[value.bill_key] = <div className='' key={index}><label>No bill components for {value.bill_key}-{prg}</label><br /></div>;
        });
        let errLabels = [];
        for (let k in errs) {
            errLabels.push(errs[k]);
        }
        setErrors(errLabels);
        setResult(response);

        let final = [];

        const selectedFilters = filterAdd.record;
        for (let i = 0; i < result.length; i++) {
            const row = result[i];
            let allIn = true;
            for (let key in selectedFilters) {
                if (key in row && selectedFilters[key] == row[key]) {
                    allIn = true;
                } else {
                    allIn = false;
                    break;
                }
            }
            if (allIn) {
                final.push(row);
            }
        }

        let components = {};
        const cmp = valuesStore.getValue('student_bill_components');
        cmp?.forEach(v => {
            components[v.component_name] = 0;
        });

        let totalBankPayments = 0;
        let rpt = [];

        const students = utils.groupBy(final, 'applicant_id');
        let i = 0;
        for (let student in students) {
            const len = students[student].length;
            const std = students[student];
            const v = students[student][len - 1];
            const details = v.details;
            for (let key in details) {
                components[key] += parseFloat(details[key].allocated);
            }
            std?.forEach((v, i) => {
                totalBankPayments += parseFloat(v.amt_paid);
            });
            const prg = valuesStore.getArrayObjectsValue('programs', 'alias', v.program)?.name;
            if (details[add?.record?.component]?.allocated != undefined) {
                rpt.push({ sn: (i + 1), txn_id: v.bank_txn_id, acadyr: add?.record?.acad_yr, component: add?.record?.component, index: v.index_no, program: prg, amount: details[add?.record?.component]?.allocated });
            }
            i++;
        }
        reportTable.setData(rpt);


        let totalSum = 0;
        let a = [];
        let inc = 1;
        const sortable = Object.fromEntries(Object.entries(components).sort(([, a], [, b]) => b.priority - a.priority));
        for (let k in sortable) {
            totalSum += components[k];
            a.push({ item: <label className='fw-bold'>{k}</label>, amount: <label className='text-primary'>{components[k]?.toFixed(2)}</label>, id: inc });
            inc++;
        }
        a.unshift(
            { item: <label className='fw-bold'>Bank Approx.</label>, amount: <label className='text-primary'> {totalBankPayments?.toFixed(2)}</label>, id: 0 },
            { item: <label className='fw-bold'>Total Allotted Aprox.</label>, amount: <label className='text-primary'> {totalSum?.toFixed(2)}</label>, id: a.length + 2 },
            { item: <label className='fw-bold'>Difference Aprox.</label>, amount: <label className='text-primary'> {totalBankPayments?.toFixed(2) - totalSum?.toFixed(2)}</label>, id: a.length + 3 },
        );
        summaryTable.setData(a);
        setQueryLoading(false);
    }

    function reset() {
        filterAdd.setRecord({});
        add.setRecord({});
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='row row-cols-2 mb-2'>
                        {/* <Space> */}

                        <div className='col-md-6x'>
                            {filterAdd.form}
                        </div>
                        <div>
                            {add.form}
                        </div>
                        <Space>
                            <Button loading={queryLoading} className='btn-success border-0' onClick={e => query()}><i className='fas fa-paper-plane me-2' /> Query</Button>
                            <Button className='btn-primary border-0' onClick={e => reset()}><i className='fas fa-user-plus me-2' /> Reset</Button>
                            <Button className='btn-success border-0' onClick={e => utils.exportXLS(excel, 'breakdown_report', 'xlsx', reportTableColumns, reportTable.data)}><i className='fas fa-file me-2' /> Excel</Button>
                        </Space>
                        {/* </Space> */}
                    </div>
                    <div className='row row-cols-3 g-2 mt-1'>
                        <div className='col p-2 h-scrolling-wrapper' style={{ height: '300px', overflowY: 'auto' }}>
                            {summaryTable.table}
                        </div>
                        <div className='col p-2 h-scrolling-wrapper bg-whitex' style={{ height: '300px', overflowY: 'auto' }}>
                            <List
                                className='bg-white'
                                size="small"
                                header={<div className='text-danger fw-bold'>{errors?.length} Errors Summaries</div>}
                                bordered
                                dataSource={errors}
                                renderItem={(item) => <List.Item>{item}</List.Item>
                                }
                            />
                        </div>
                        <div className='col p-2 h-scrolling-wrapper bg-whitex' style={{ height: '300px', overflowY: 'auto' }}>
                            <List
                                className='bg-white'
                                size="small"
                                header={<div className='text-danger fw-bold'>{result?.errors?.length && (result?.errors.length)} Transaction Errors</div>}
                                bordered
                                dataSource={result?.errors}
                                renderItem={(item) => <List.Item>{item.msg}-{valuesStore.getArrayObjectsValue('programs', 'alias', item.program)?.name}</List.Item>}
                            />
                        </div>
                    </div>

                    <div className='col-md-12 mt-3'>
                        {reportTable.tableWithHeaderFooter()}
                    </div>

                </div>
            </div>

        </>
    );
}

export default memo(BreakdownReport);