
import React, { useState, useEffect, memo, useMemo, useRef, forwardRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import { Space, Button, Tooltip, message, Divider, Drawer, Tabs, Input } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import { confirmAlert } from 'react-confirm-alert';
import useDrawer from '../../hooks/drawer';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const Students = (props) => {
    const extraFiltersAddMeta = 'extraFiltersAddMeta';
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const extraFiltersAdd = useAdd(extraFiltersAddMeta, 'table_name');
    const programChange = useAdd('tables_metadata', 'table_name');
    const [indexNo, setIndexNo] = useState('');
    const [applicantID, setApplicantID] = useState('');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const studentDetailsDrawer = useDrawer();
    const studentStatement = useRef();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,// `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { sex: 'IN' },
        { table: 'students', fields: ['*'] }
    );


    const columns = ([
        {
            title: 'First Name',
            dataIndex: 'fname',
            ...table.getColumnSearchProps('fname'),
        },
        {
            title: 'Last Name',
            dataIndex: 'lname',
            ...table.getColumnSearchProps('lname'),
        },
        {
            title: 'Middle Name',
            dataIndex: 'mname',
            ...table.getColumnSearchProps('mname'),
        },
        {
            title: 'Index Number',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        {
            title: 'Gender',
            dataIndex: 'sex',
            ...table.getColumnSearchProps('sex'),
        },
        {
            title: 'Current Level',
            dataIndex: 'current_level',
            ...table.getColumnSearchProps('current_level'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Tooltip title="View Student"><Button className='btn-primary border-0' onClick={e => CustomFunctions.viewStudentBasicData(record, studentDetailsDrawer, valuesStore)}><i className='fas fa-eye' /></Button></Tooltip>
                    <Tooltip title="View Student Statement"><Button className='btn-info border-0' onClick={e => CustomFunctions.viewStudentPayments(record, studentDetailsDrawer, valuesStore, studentStatement, ReactToPrint)}><i className='fas fa-money-bill' /></Button></Tooltip>
                    {/* <Tooltip title="Change Program and/or Session"><Button onClick={e => changeStudentProgram(record, 'program_session_change')}><i className='fas fa-file text-primary' /></Button></Tooltip> */}
                    {/* <Tooltip title="Edit Student"><Button onClick={e => editRecord(record, 'students')}><i className='fas fa-edit text-success' /></Button></Tooltip> */}
                    {/* <Tooltip title="Delete Student">{del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'students', where: 'id', whereType: 'closed' },
                        <Button><i className='fas fa-trash text-danger' /></Button>
                    )}
                    </Tooltip> */}
                </Space>
            },
        },
    ]);


    function setupExtraFilters() {
        const table_name = 'student_filters';
        setTimeout(e => {
            valuesStore.setValue(extraFiltersAddMeta, [
                {
                    id: '', table_name: table_name,
                    column_name: 'applicant_id', col_real_name: 'Applicant ID', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'index_no', col_real_name: 'Index No.', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'fname', col_real_name: 'Firstname', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'lname', col_real_name: 'Lastname', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'mname', col_real_name: 'Middlename', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'sex', col_real_name: 'Sex', type: 'csvMultiSelect',
                    options: 'Male,Female',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'telephone', col_real_name: 'Telephone', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'personal_email', col_real_name: 'Personal Email', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'program', col_real_name: 'Program', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT alias,short_name FROM programs","key":"alias","value":"short_name"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'session', col_real_name: 'Session', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT alias,session FROM sessions","key":"alias","value":"session"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'campus', col_real_name: 'Campus', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT alias,name FROM organizational_type WHERE type IN (2)","key":"alias","value":"name"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'faculty_id', col_real_name: 'Faculty', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT alias,name FROM organizational_type WHERE type IN (4)","key":"alias","value":"name"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'dept_id', col_real_name: 'Department', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT alias,name FROM organizational_type WHERE type IN (5)","key":"alias","value":"name"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'student_type', col_real_name: 'Student Type', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT type FROM student_type","key":"type","value":"type"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'start_acadyr', col_real_name: 'Start Acad Yr.', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'end_acadyr', col_real_name: 'End Acad Yr.', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'current_acadyr', col_real_name: 'Current Acad Yr.', type: 'text',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'start_level', col_real_name: 'Start Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'end_level', col_real_name: 'End Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'current_level', col_real_name: 'Current Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'status', col_real_name: 'Status', type: 'csvMultiSelect',
                    options: 'active,deferred,suspended,completed',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'current_sem', col_real_name: 'Current Semester', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT semester,alias FROM semester","key":"alias","value":"semester"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'batch', col_real_name: 'Batch', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT DISTINCT batch FROM students","key":"batch","value":"batch"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'start_date', col_real_name: 'Start Date', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT DISTINCT start_date FROM students","key":"start_date","value":"start_date"}',
                    backend_visible: 1, rank: 1
                },
                {
                    id: '', table_name: table_name,
                    column_name: 'end_date', col_real_name: 'End Date', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT DISTINCT end_date FROM students","key":"end_date","value":"end_date"}',
                    backend_visible: 1, rank: 1
                },
            ]);
            extraFiltersAdd.setTblName(table_name);
        }, 1000);
    }

    function applyFilter() {
        table.rowSelectionDebug();
        table.setSelectedRows([]);
        if (extraFiltersAdd.record?.applicant_id) {
            let values = extraFiltersAdd.record?.applicant_id.split(',').map(v => v);
            filters['applicant_id'] = values;
        }
        if (extraFiltersAdd.record?.telephone) {
            let values = extraFiltersAdd.record?.telephone.split(',').map(v => v);
            filters['telephone'] = values;
        }
        if (extraFiltersAdd.record?.personal_email) {
            let values = extraFiltersAdd.record?.personal_email.split(',').map(v => v);
            filters['personal_email'] = values;
        }
        if (extraFiltersAdd.record?.program) {
            let values = extraFiltersAdd.record?.program.split(',').map(v => v);
            filters['program'] = values;
            filterTypes['program'] = 'IN';
        }
        if (extraFiltersAdd.record?.session) {
            let values = extraFiltersAdd.record?.session.split(',').map(v => v);
            filters['session'] = values;
            filterTypes['session'] = 'IN';
        }
        if (extraFiltersAdd.record?.campus) {
            let values = extraFiltersAdd.record?.campus.split(',').map(v => v);
            filters['campus'] = values;
            filterTypes['campus'] = 'IN';
        }
        if (extraFiltersAdd.record?.faculty_id) {
            let values = extraFiltersAdd.record?.faculty_id.split(',').map(v => v);
            filters['faculty_id'] = values;
            filterTypes['faculty_id'] = 'IN';
        }
        if (extraFiltersAdd.record?.dept_id) {
            let values = extraFiltersAdd.record?.dept_id.split(',').map(v => v);
            filters['dept_id'] = values;
            filterTypes['dept_id'] = 'IN';
        }
        if (extraFiltersAdd.record?.student_type) {
            let values = extraFiltersAdd.record?.student_type.split(',').map(v => v);
            filters['student_type'] = values;
            filterTypes['student_type'] = 'IN';
        }
        if (extraFiltersAdd.record?.start_acadyr) {
            let values = extraFiltersAdd.record?.start_acadyr.split(',').map(v => v);
            filters['start_acadyr'] = values;
        }
        if (extraFiltersAdd.record?.end_acadyr) {
            let values = extraFiltersAdd.record?.end_acadyr.split(',').map(v => v);
            filters['end_acadyr'] = values;
        }
        if (extraFiltersAdd.record?.current_acadyr) {
            let values = extraFiltersAdd.record?.current_acadyr.split(',').map(v => v);
            filters['current_acadyr'] = values;
        }
        if (extraFiltersAdd.record?.start_level) {
            let values = extraFiltersAdd.record?.start_level.split(',').map(v => v);
            filters['start_level'] = values;
        }
        if (extraFiltersAdd.record?.end_level) {
            let values = extraFiltersAdd.record?.end_level.split(',').map(v => v);
            filters['end_level'] = values;
        }
        if (extraFiltersAdd.record?.current_level) {
            let values = extraFiltersAdd.record?.current_level.split(',').map(v => v);
            filters['current_level'] = values;
        }
        if (extraFiltersAdd.record?.status) {
            let values = extraFiltersAdd.record?.status.split(',').map(v => v);
            filters['status'] = values;
        }
        if (extraFiltersAdd.record?.current_sem) {
            let values = extraFiltersAdd.record?.current_sem.split(',').map(v => v);
            filters['current_sem'] = values;
        }
        if (extraFiltersAdd.record?.batch) {
            let values = extraFiltersAdd.record?.batch.split(',').map(v => v);
            filters['batch'] = values;
            filterTypes['batch'] = 'IN';
        }
        if (extraFiltersAdd.record?.start_date) {
            let values = extraFiltersAdd.record?.start_date.split(',').map(v => v);
            filters['start_date'] = values;
        }
        if (extraFiltersAdd.record?.end_date) {
            let values = extraFiltersAdd.record?.end_date.split(',').map(v => v);
            filters['end_date'] = values;
        }

        if (extraFiltersAdd.record?.index_no) {
            let values = extraFiltersAdd.record?.index_no.split(',').map(v => v);
            filters['index_no'] = values;
        }
        if (extraFiltersAdd.record?.fname) {
            let values = extraFiltersAdd.record?.fname.split(',').map(v => v);
            filters['fname'] = values;
        }
        if (extraFiltersAdd.record?.lname) {
            let values = extraFiltersAdd.record?.lname.split(',').map(v => v);
            filters['lname'] = values;
        }
        if (extraFiltersAdd.record?.mname) {
            let values = extraFiltersAdd.record?.mname.split(',').map(v => v);
            filters['mname'] = values;
        }
        if (extraFiltersAdd.record?.sex) {
            let values = extraFiltersAdd.record?.sex.split(',').map(v => v);
            filters['sex'] = values;
            filterTypes['sex'] = 'IN';
        }


        table.setTableParams(r => ({ ...r, filters: { ...r.filters, ...filters }, filterTypes: { ...r.filterTypes, ...filterTypes } }));
        //this is fixing a bug
        if (Object.keys(filters) <= 0) {
            table.setTableParams(r => ({ ...r, filters }));
        }
    }


    async function resetPassword(record) {
        confirmAlert({
            title: 'Confim student password reset',
            message: 'Continuing will reset student\'s account to default. Click on Yes to continue. Click on No to cancel',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const res = await utils.requestWithReauth('post', `${Settings.backend}/reset_student_password`, null, record);
                        if (res.status == 'Ok') {
                            message.success('Operation successful');
                        } else {
                            message.error(res.msg);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });



    }


    function changeStudentProgram(record, tableName) {
        programChange.setRecord({
            pc_program: record.program,
            pc_session: record.session,
            pc_start_level: record.start_level,
            pc_end_level: record.end_level,
            pc_start_acadyr: record.start_acadyr,
            pc_end_acadyr: record.end_acadyr,
            indexNo: record.index_no
        });
        programChange.setTblName(tableName);
        programChange.setShowModal(true);
        programChange.setSaveCompleted(false);
    }




    function editRecord(record, tableName) {
        record = utils.correctDateInRecord(record, true);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }


    async function editOnOk() {
        //SAVE TO STD_MGT
        let res1 = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'students',
            { tbl: 'students', where: 'id', whereType: 'closed' },
        );
        //SAVE TO FINANCE
        const rc = valuesStore.getArrayObjectsValue('settings', 'prop', 'studentUpdateOnFinanceUrl');
        let res2 = await edit.save(undefined,
            rc.value,
            'students',
            { tbl: 'students', where: 'id', whereType: 'closed' },
        );
    }


    // const colFilters = [
    //     {
    //         filter: 'dept',
    //         sql: "SELECT name,alias FROM organizational_type WHERE type IN (4,5) ORDER BY name ",
    //         key: 'alias',
    //         value: 'name'
    //     }
    // ];

    async function programChangeOk() {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/change_program_session`, null, programChange.record);
        console.log(res);
    }

    useMemo(() => {
        setupExtraFilters();
        table.setColumns(columns);
        // table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')


    }, [edit.saveCompleted, del.saveCompleted, table.extraFetchParams, programChange.saveCompleted]);

    function query() {
        table.setDataURL(`${Settings.backend}/get_data`);
        applyFilter();
        setFilterDrawerOpen(false);
    }

    function querySimple() {
        table.setDataURL(`${Settings.backend}/get_data`);
        applySimpleFilter();
    }

    function clear() {
        navigate(0);
    }

    function applySimpleFilter() {
        table.rowSelectionDebug();
        table.setSelectedRows([]);
        filters['applicant_id'] = [applicantID];
        filters['index_no'] = [indexNo];
        table.setTableParams(r => ({ ...r, filters: { ...r.filters, ...filters }, filterTypes: { ...r.filterTypes, ...filterTypes } }));
        //this is fixing a bug
        if (Object.keys(filters) <= 0) {
            table.setTableParams(r => ({ ...r, filters }));
        }
    }


    const filterTabs = [
        {
            key: '1',
            label: 'Basic Filters',
            children: <>
                <div className='row row-cols-2'>
                    {extraFiltersAdd?.form?.map((v, i) => <div key={i}>{v}</div>)}
                </div>
                <Space>
                    <Button onClick={e => query()} className='btn-success border-0'> <i className='me-2 fas fa-paper-plane' /> Query</Button>
                    <Button onClick={e => clear()} className='btn-info border-0'> <i className='me-2 fas fa-filter' /> Clear All</Button>
                </Space></>,
        },
        {
            key: '2',
            label: 'Advanced Filters',
            children: 'Will be available in future updates',
        }
    ];


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div className='d-flex mb-2'>
                            <Input addonBefore={<><i className='fas fa-user' /> Enter Index Number</>} className='me-2' value={indexNo} onChange={e => setIndexNo(e.target.value)} />
                            <Input addonBefore={<><i className='fas fa-user' /> Enter Applicant ID </>} className='' value={applicantID} onChange={e => setApplicantID(e.target.value)} />
                        </div>
                        <div className='d-flex'>
                            <Button onClick={e => querySimple()} className='btn-success border-0 me-2'> <i className='me-2 fas fa-paper-plane' /> Query</Button>
                            <Button onClick={e => setFilterDrawerOpen(true)} className='btn-primary border-0'> <i className='me-2 fas fa-filter' /> Apply More Filters</Button>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        {/* <Divider orientation='left'><label className='text-danger'>Student List</label></Divider> */}
                        {table.tableWithHeaderFooter()}
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Item', editOnOk)}
            {programChange.addModal('Make changes', programChangeOk)}
            <Drawer zIndex={1020} width={'100%'} title="Filters" placement="right" onClose={e => setFilterDrawerOpen(false)} open={filterDrawerOpen}>
                <Tabs defaultActiveKey="1" items={filterTabs} />
            </Drawer>
            {studentDetailsDrawer.drawerJSX(1021)}
        </>
    );
}
// 
export default memo(Students);