
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Input, Form, Divider, message, Typography, Tag } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDynamicForm from '../../hooks/dynamic_form';


const StudentBills = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const billTemplatesAddAll = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const billTemplatesAddMeta = 'billTemplatesAddMeta';
    const billTemplatesAdd = useAdd(billTemplatesAddMeta, 'table_name');
    // const navigate = useNavigate();


    const formName = 'bill_template_form';
    const dynamicForm = useDynamicForm(formName, null, { type: 'primary', text: 'Submit' });
    const bindFeeFormName = 'bind_fee';
    const bindFee = useDynamicForm(bindFeeFormName, null, { type: 'primary', text: 'Submit' }, submitBoundedFee, false);
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['topRight', 'bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_student_bill`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'student_bill', fields: ['*'] });


    const columns = ([
        {
            title: 'Fee',
            dataIndex: 'fee_amt',
            ...table.getColumnSearchProps('fee_amt'),
        },
        {
            title: 'InWords',
            dataIndex: 'fee_word',
            ...table.getColumnSearchProps('fee_word'),
            render: (v, record) => {
                return utils.truncateText(v);
            }
        },
        {
            title: 'Level',
            dataIndex: 'level',
            ...table.getColumnSearchProps('level'),
        },
        {
            title: 'Type',
            dataIndex: 'student_type',
            ...table.getColumnSearchProps('student_type'),
            render: (v, record) => {
                return <Tag color='blue-inverse'>{v[0]}</Tag>
            }
        },
        {
            title: 'Session',
            dataIndex: 'session',
            ...table.getColumnSearchProps('session')
        },
        {
            title: 'Program',
            dataIndex: 'name',
            ...table.getColumnSearchProps('name')
        },
        {
            title: 'AcadYear',
            dataIndex: 'acad_year',
            ...table.getColumnSearchProps('acad_year')
        },
        {
            title: 'Elvl',
            dataIndex: 'undergrad_postgrad',
            render: (v, record) => {
                return <Tag color='green-inverse'>{v == 1 ? 'U' : v == 2 ? 'P' : 'N/A'}</Tag>
            }
        },
        {
            title: 'Cat',
            dataIndex: 'program_category',
            filterSearch: true
        },
        {
            title: 'SubCat',
            dataIndex: 'sub_category',
            filterSearch: true
        },
        {
            title: 'Template',
            dataIndex: 'template_id',
            ...table.getColumnSearchProps('template_id')
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => editRecord(record, 'student_bill')}><i className='fas fa-edit text-successx' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'student_bill', where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0'><i className='fas fa-trash text-dangerx' /></Button>
                    )}
                </Space>
            },
        },
    ]);

    const colFilters = [
        {
            filter: 'program_category',
            sql: "SELECT type FROM cert_type_index_starter WHERE is_sub = 0",
            key: 'type',
            value: 'type'
        },
        {
            filter: 'sub_category',
            sql: "SELECT type FROM cert_type_index_starter WHERE is_sub = 1",
            key: 'type',
            value: 'type'
        },
        {
            filter: 'undergrad_postgrad',
            sql: "SELECT DISTINCT undergrad_postgrad FROM programs",
            key: 'undergrad_postgrad',
            value: 'undergrad_postgrad'
        }
    ];

    function editRecord(record, tableName) {
        // utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'student_bill') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    function bindTemplateAll(tableName = 'STUDENT_BILL_TEMPL_BIND_ALL') {
        billTemplatesAddAll.setTblName(tableName);
        billTemplatesAddAll.setShowModal(true);
        billTemplatesAddAll.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'student_bill',
            { tbl: 'student_bill', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_new_student_bill`, null, add.record);
        if (res.status == 'Ok') {
            add.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
    }

    async function bindBillTemplate() {
        const data = { programs: table.selectedRows, ...billTemplatesAdd.record };
        let res = await utils.requestWithReauth('post', `${Settings.backend}/bind_bill_template`, null, data);
        if (res.status == 'Ok') {
            message.success('Operation successful');            
            billTemplatesAdd.reset();
        } else {
            message.error(res.msg);
        }
    }

    async function bindBillTemplateAll() {        
        let res = await utils.requestWithReauth('post', `${Settings.backend}/bind_bill_template_all`, null, billTemplatesAddAll.record);
        if (res.status == 'Ok') {
            message.success('Operation successful');
            billTemplatesAddAll.reset();
        } else {
            message.error(res.msg);
        }
    }


    function setFormFields() {
        const components = valuesStore.getValue('student_bill_components');
        const componentOptions = components?.map(v => ({ value: v.component_name, label: v.component_name }));
        const formData = [
            { name: 'component', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter component name', inputType: 'select', options: componentOptions, width: 200 },
            { name: 'fee', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter fee', inputType: 'text', width: 90 },
            { name: 'priority', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter priority', inputType: 'number', width: 70 }
        ];
        dynamicForm.setFormFieldsToCreate(formData);
    }

    function createTemplate() {
        setFormFields();
        dynamicForm.setFormChildren(
            <div className='d-flex flex-column w-100'>
                <Form.Item
                    key={'template_name'}
                    name={'template_name'}
                    label={'Template Name'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>
                    <Input placeholder='Name' />
                </Form.Item>
                <Form.Item
                    key={'template_alias'}
                    name={'template_alias'}
                    label={'Alias'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>
                    <Input placeholder='Alias' />
                </Form.Item>
                <Form.Item
                    key={'template_description'}
                    name={'template_description'}
                    label={'Description'}
                >
                    <Input.TextArea placeholder="Description " autoSize />
                </Form.Item>
                <Divider orientation='middle'>Items</Divider>
            </div>
        );
        dynamicForm.setShowModal(true);
        dynamicForm.form.resetFields();
    }

    function billTemplates() {
        setTimeout(e => {
            const table = 'billTemplates';
            valuesStore.setValue(billTemplatesAddMeta, [
                {
                    id: '', table_name: table,
                    column_name: 'template', col_real_name: 'Template', type: 'sqlSelect',
                    options: '{"sql":"SELECT * FROM student_bill_templates","key":"alias","value":"name,description"}',
                    extra_options: '',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                }]);
            billTemplatesAdd.setTblName(table);
        }, 1000);
    }

    function showBindFeeModal() {
        bindFee.setFormChildren(
            <div className='d-flex flex-column w-100'>
                <Form.Item
                    key={'fee_amount'}
                    name={'fee_amount'}
                    label={'Fee Aamount'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>
                    <Input type='number' placeholder='Fee Amount' />
                </Form.Item>
                <Form.Item
                    key={'fee_word'}
                    name={'fee_word'}
                    label={'Fee in Words'}
                >
                    <Input.TextArea placeholder="Fee in Words " autoSize />
                </Form.Item>
            </div>
        );
        bindFee.setShowModal(true);
        bindFee.form.resetFields();
    }

    function submitTemplate() {
        dynamicForm.setFormSubmit({
            async onFormSubmit(values) {
                let res = await utils.requestWithReauth('post', `${Settings.backend}/save_bill_template`, null, values);
                if (res.status === 'Ok') {
                    message.success('Template has been created successfully');
                    dynamicForm.setShowModal(false);
                } else {
                    message.error(res.msg);
                }
            }
        });
    }

    async function submitBoundedFee(values) {
        if (!table.selectedRows.length) {
            message.error('No bills were selected');
            return;
        }
        const data = { ...values, bills: table.selectedRows };
        let res = await utils.requestWithReauth('post', `${Settings.backend}/bind_fee_amount`, null, data);
        if (res.status === 'Ok') {
            message.success('Fee bind successful');
            bindFee.setShowModal(false);
        } else {
            message.error(res.msg);
        }
    }


    function showBillTemplates() {
        billTemplatesAdd.setTblName('billTemplates');
        billTemplatesAdd.setSaveCompleted(false);
        billTemplatesAdd.setShowModal(true);
    }

    useMemo(() => {
        // submitFeeBind()        
        billTemplates();
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
        submitTemplate();
        table.setAllowSelection(true);
        computeTotalFees(dynamicForm?.allValues?.[formName]);
    }, [dynamicForm.currentChangedValue, billTemplatesAdd.saveCompleted, add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);

    function computeTotalFees(formValues) {
        const total = formValues?.reduce((prev, curr) => {
            if (curr) {
                if (curr.fee) {
                    return prev + parseFloat(curr.fee);
                }
            }
        }, 0);
        dynamicForm.setChildrenTop(<Typography>
            <pre className='text-danger fw-bold'>Total Computed Fees (GHC): {total}</pre>
        </Typography>)
    }



    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Bill</Button>
                            <Button className='btn-secondary border-0' onClick={e => createTemplate()}><i className='fas fa-user-plus me-2' /> Create Bill Template</Button>
                            <Button className='btn-warning border-0' onClick={e => showBillTemplates()}><i className='fas fa-user-plus me-2' /> Bind Template</Button>
                            <Button className='btn-success border-0' onClick={e => showBindFeeModal()}><i className='fas fa-user-plus me-2' /> Bind Fee </Button>
                            <Button className='btn-success border-0' onClick={e => bindTemplateAll()}><i className='fas fa-user-plus me-2' /> Bind Template All </Button>
                            <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} />
                            <Button className='btn-success border-0' onClick={e => utils.filterByDate('date_inserted', dateRange, table)}><i className='fas fa-filter me-2' />Filter</Button>
                        </Space>
                    </div>
                    <div className='col-md-12'>
                        <div className='table-responsive h-scrolling-wrapper'>
                            {table.table}
                        </div>
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
            {dynamicForm.formModal('Bill Template', undefined, undefined, undefined, 700)}
            {bindFee.formModal('Bind Fee', undefined, undefined, undefined)}
            {billTemplatesAdd.addModal('Bind Bill Template', bindBillTemplate, undefined, undefined, 700)}
            {billTemplatesAddAll.addModal('Bind Bill Template', bindBillTemplateAll, undefined, undefined, 700)}
        </>
    );
}

export default memo(StudentBills);