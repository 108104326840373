
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import { Space, Button, Input, Select, Checkbox, Alert, Tag, Tooltip, Divider, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useTable from '../../hooks/table';
import useDrawer from '../../hooks/drawer';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Utilities from '../../dependencies/custom/react-utilities';


const ManualTxn = (props) => {
    const valuesStore = ValuesStore();
    const editManualTxn = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 

    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [btnPermissions, setBtnPermissions] = useState({});

    const [indexNumber, setIndexNumber] = useState();
    const [exemptIndexNo, setExemptIndexNo] = useState(false);


    const [studentFound, setStudentFound] = useState(false);
    const [studentInfoBanner, setStudentInfoBanner] = useState();
    const [alertMsg, setAlertMsg] = useState("Student details will appear here");

    // const drawer = useDrawer();




    const txnTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'manual_txn_entry', fields: ['*'] });


    const debtorTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 20,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'balances', fields: ['*'] });


    const txnColumns = ([
        {
            title: 'TxnID',
            dataIndex: 'txn_id',
            ...txnTable.getColumnSearchProps('txn_id')
        },
        {
            title: 'Bank',
            dataIndex: 'bank_code',
            filterSearch: true
        },
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...txnTable.getColumnSearchProps('index_no')
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...txnTable.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return new Date(utils.formatDate(v)).toLocaleDateString();
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-primary border-0' onClick={e => setNFetchStudent(record)}><i className='fas fa-user' /></Button>
                    <Button className='btn-info border-0' onClick={e => repushTxn(record)}><i className='fas fa-redo' /></Button>
                </Space>
            },
        },
    ]);

    const debtorsColumns = ([
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...debtorTable.getColumnSearchProps('index_no')
        },
        {
            title: 'Debt',
            dataIndex: 'balance'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-primary border-0' onClick={e => setNFetchStudent(record)}><i className='fas fa-user' /></Button>

                    {btnPermissions?.delete_balances && <Tooltip title="Delete Debt">
                        {del.confirm(
                            `${Settings.backend}/delete`,
                            record,
                            'Are you sure to delete this item',
                            { tableName: 'balances', where: 'id', whereType: 'closed' },
                            <Button className='btn-danger border-0'>  <i className='fas fa-trash text-dangerx' /></Button>,
                            undefined, undefined, undefined
                        )}
                    </Tooltip>}
                </Space>
            },
        },
    ]);
    const colFilters = [
        {
            filter: 'bank_code',
            sql: 'SELECT bank_code,bank_name FROM bank_accounts WHERE is_live = 1',
            key: 'bank_code',
            value: 'bank_name'
        }
    ];

    async function setNFetchStudent(record) {
        setIndexNumber(record?.index_no);
        await getStudentDetails2(record?.index_no);
    }

    const manualTxnItems = 'manual_txn_meta';
    const addManualTxn = useAdd(manualTxnItems, 'table_name');
    function setupManualTxnItems() {
        setTimeout(e => {
            valuesStore.setValue(manualTxnItems, [
                {
                    id: '', table_name: 'MANUAL_TXN_ENTRY',
                    column_name: 'student_id', col_real_name: 'StudentID', type: 'text',
                    backend_visible: 0, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'MANUAL_TXN_ENTRY',
                    column_name: 'txn_id', col_real_name: 'TxnID', type: 'text',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'MANUAL_TXN_ENTRY',
                    column_name: 'bank', col_real_name: 'Bank', type: 'sqlSelect',
                    options: '{ "sql": "Select bank_code,bank_name FROM bank_accounts WHERE is_live = 1", "value": "bank_name", "key": "bank_code" }',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'MANUAL_TXN_ENTRY',
                    column_name: 'teller', col_real_name: 'Teller', type: 'text',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'MANUAL_TXN_ENTRY',
                    column_name: 'amt', col_real_name: 'Amount', type: 'text',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'MANUAL_TXN_ENTRY',
                    column_name: 'date', col_real_name: 'TxnDate', type: 'date',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                }

            ]);
            addManualTxn.setTblName('MANUAL_TXN_ENTRY');
        }, 3000);
    }

    function repushTxn(record) {
        setNFetchStudent(record);
        addManualTxn.setRecord({ amt: record?.amount, txn_id: record?.txn_id, bank: record?.bank_code, teller: record?.teller, date: record?.date });        
    }

    useMemo(() => {
        // setTableDataURL();
        showBtnUsingPermissions();
        setupManualTxnItems();

        txnTable.setColumns(txnColumns);
        txnTable.setColFilters(colFilters, txnColumns, `${Settings.backend}/get_col_filters`);

        debtorTable.setColumns(debtorsColumns);


        // console.log(33);
        // console.log(addManualTxn.form)
    }, [addManualTxn.saveCompleted, editManualTxn.saveCompleted, del.saveCompleted, txnTable.extraFetchParams, debtorTable.extraFetchParams, btnPermissions]);





    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {

            const permList = [
                { perm: 'save_receipt_manually', table: '' },
                { perm: 'delete_balances', table: 'balances' },
            ];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v.perm, v.table, valuesStore);
                setBtnPermissions(r => ({ ...r, [v.perm]: perm }))
            });
        }
    }



    function changeIndexNoActiveState() {
        setExemptIndexNo(!exemptIndexNo);
        setIndexNumber('');
    }

    async function getStudentDetails() {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { indexNumber });
        if (res?.result?.length) {
            setStudentFound(true);
            studentDetails(res?.result[0]);
        } else {
            setAlertMsg('Student not found');
            setStudentFound(false);
            changeIndexNoActiveState();
        }
    }

    async function getStudentDetails2(indexNo) {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { indexNumber: indexNo });
        if (res?.result?.length) {
            setStudentFound(true);
            studentDetails(res?.result[0]);
        } else {
            setAlertMsg('Student not found');
            setStudentFound(false);
            changeIndexNoActiveState();
        }
    }

    function studentDetails(studentInfo) {
        const details = <div className="container">
            <div className="row row-cols-3 p-2 blue lighten-5 rounded">
                <div className="col">
                    First Name: {studentInfo?.fname}<br />
                    Last Name: {studentInfo?.lname}<br />
                    Middle Name: {studentInfo?.mname}<br />
                </div>
                <div className="col">
                    Index Number: {studentInfo?.index_no}<br />
                    Program: {studentInfo?.program_name}<br />
                    Level: {studentInfo?.current_level}
                </div>
                <div className="col">
                    Session: {studentInfo?.session}<br />
                    Current Semester :{studentInfo?.current_sem}
                </div>
            </div>
        </div>;
        setStudentInfoBanner(details);
    }

    async function submitEntry() {
        const record = { ...addManualTxn.record, student_id: indexNumber };
        const res = await utils.requestWithReauth('post', `${Settings.backend}/save_receipt_manually`, null, record);
        if (res.status == 'Ok' || res.msg == 'Ok') {
            Utilities.showNotification('Attention!', 'Transaction has been recorded');
        } else {
            if (res?.msg) {
                Utilities.showNotification(res?.msg, res?.details);
            }
        }

    }



    return (
        <>
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div className='mb-2'>
                            {studentFound ? studentInfoBanner : <Alert message={alertMsg} type="info" showIcon />}
                        </div>

                        <div className='d-flex'>
                            <Input
                                addonBefore={<Checkbox checked={exemptIndexNo} onChange={e => changeIndexNoActiveState()}>Exempt Index Number/Applicant ID</Checkbox>}
                                disabled={exemptIndexNo}
                                type='text'
                                placeholder='Enter Index Number/Applicant ID'
                                value={indexNumber}
                                onChange={e => setIndexNumber(e.target.value)}
                                className='w-100 me-3'
                                size="large"
                                onPressEnter={e => getStudentDetails()} />
                            <Button size='large' type='primary' onClick={e => getStudentDetails()} > <i className='fas fa-search me-2' /> Fetch Student</Button>
                        </div>
                    </div>
                    <div className='col-md-12 mb-2'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='borderx rounded p-2'>
                                    <label className='fw-bold'>Transaction Form</label>
                                    {addManualTxn.form}
                                    {btnPermissions?.save_receipt_manually && <Button onClick={e => submitEntry('cr')} className='btn-success border-0' icon={<i className='fas fa-paper-plane me-2' />}>Submit</Button>}
                                </div>
                                <div>
                                    {txnTable.table}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <label className='fw-bold'>Debt Balance BF</label>
                                {debtorTable.table}
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* {drawer.drawerJSX()} */}
        </>
    );
}

export default memo(ManualTxn);