import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';
import Settings from '../dependencies/custom/settings';
const store = (set) => ({
    tables_metadata: {
        method: 'post',
        table: 'tables_metadata',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'tables_metadata',
    },
    pay_items: {
        method: 'post',
        table: 'pay_items',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'pay_items',
    },
    programs: {
        method: 'post',
        table: 'programs',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'programs',
        critfdx: ['show_hide'],
        critval: ['1'],
        fields: ['*']
    },
    student_bill_components: {
        method: 'post',
        table: 'student_bill_components',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'student_bill_components',
    },
    settings: {
        method: 'post',
        table: 'settings',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'settings',
        critfdx: ['is_public'],
        critval: ['1'],
        fields: ['*']
    },
    // course: {
    //     method: 'post',
    //     table: 'courses',
    //     url: `${Settings.backend}/bootstrap`,
    //     storeName: 'courses',
    // },

    // category: {  
    //     method:'post',      
    //     table: 'category',
    //     url: `${Settings.backend}/bootstrap`,        
    //     storeName: 'categories',
    //     treeDataStoreName:'categoryTreeSelectData',
    //     treeDataStoreNameNoDisabled:'categoryTreeSelectDataNoDisabled'
    // },

    // reg_dist: {  
    //     method:'post',      
    //     table: 'reg_dist',        
    //     url: `${Settings.backend}/bootstrap`,        
    //     storeName: 'reg_dist',
    //     treeDataStoreName:'regDistTreeSelectData',
    //     treeDataStoreNameNoDisabled:'regDistTreeSelectDataNoDisabled'
    // },

    // filters: {  
    //     method:'post',      
    //     table: 'filters',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'filters',        
    // },

    // filter_opt: {  
    //     method:'post',      
    //     table: 'filter_opt',        
    //     url: `${Settings.backend}/bootstrap`,       
    //     storeName: 'filter_opt',        
    // },

    // filter_to_category:{
    //     method:'post',      
    //     table: 'filter_to_category',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'filter_to_category', 
    // },

    // package:{
    //     method:'post',      
    //     table: 'package',        
    //     url: `${Settings.backend}/bootstrap`,     
    //     storeName: 'package', 
    // },

    // package_to_category:{
    //     method:'post',      
    //     table: 'package_to_category',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'package_to_category', 
    // },

    // convo_starter:{
    //     method:'post',      
    //     table: 'convo_starter',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'convo_starter', 
    // },
    // user_extra_details_opt:{
    //     method:'post',      
    //     table: 'user_extra_details_opt',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'user_extra_details_opt', 
    // }
});

const useStore = create(devtools(store));

const useTrackedStore = createTrackedSelector(useStore);
export default useTrackedStore;

