
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Tooltip, message, Tag, Divider } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDrawer from '../../hooks/drawer';
import CustomFunctions from '../../dependencies/custom/custom-functions';

const ProcessEntries = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const drawer = useDrawer();
    
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const [btnPermissions, setBtnPermissions] = useState({});
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        // `${Settings.backend}/get_data`,
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'manual_txn', fields: ['*'] });

    
    const columns = ([

        {
            title: 'TxnID',
            dataIndex: 'bank_txn_id',
            ...table.getColumnSearchProps('bank_txn_id')
        },
        {
            title: 'AmtPaid',
            dataIndex: 'amt_paid'
        },
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no')
        },
        {
            title: 'Teller',
            dataIndex: 'teller',
            ...table.getColumnSearchProps('teller')
        },
        {
            title: 'Flow',
            dataIndex: 'debit_credit',
            render: (v, record) => {
                return <Tag
                    color={v == 'dr' ? '#2db7f5' : 'geekblue-inverse'}
                >{v.toUpperCase()}</Tag>

            },
            filters: [
                {
                    value: 'dr',
                    text: 'Debit'
                },
                {
                    value: 'cr',
                    text: 'Credit'
                }
            ]
        },
        {
            title: 'PayItem',
            dataIndex: 'pay_item',
            filterSearch: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (v, record) => {
                return <Tag
                    color={v == 'entry' ? 'processing' : v == 'review' ? 'cyan' : v == 'approve' ? 'purple' : 'volcano'}
                    icon={v == 'entry' ? <i className='fas fa-clock me-1' /> : v == 'review' ? <i className='fas fa-check me-1' /> : v == 'approve' ? <i className='fas fa-check-double me-1' /> : <i className='fas fa-times me-1' />}
                >{v}</Tag>

            }
            ,
            filters: [
                {
                    value: 'entry',
                    text: 'Entry'
                },
                {
                    value: 'review',
                    text: 'Review'
                },
                {
                    value: 'approve',
                    text: 'Approve'
                },
                {
                    value: 'decline',
                    text: 'Decline'
                }
            ]
        },
        {
            title: 'Rev.',
            dataIndex: 'is_reversal',
            render: (v, record) => {
                return <Tag
                    color={v ? 'grey' : '#00695c'}
                >{v ? 'Y' : 'N'}</Tag>
            },
            filters: [
                {
                    value: '0',
                    text: 'Not Reversal'
                },
                {
                    value: '1',
                    text: 'Reversal'
                }
            ]
        },
        {
            title: 'Dec.',
            dataIndex: 'declined',
            render: (v, record) => {
                return <Tag
                    color={v ? 'red' : 'grey'}
                >{v ? 'Y' : 'N'}</Tag>
            },
            filters: [
                {
                    value: '0',
                    text: 'Not Declined'
                },
                {
                    value: '1',
                    text: 'Declined'
                }
            ]
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return new Date(utils.formatDate(v)).toLocaleDateString();
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    {btnPermissions?.view_manual_txn_details && <Tooltip title="View Details">
                        <Button className='btn-primary border-0' onClick={e => CustomFunctions.viewTxn(record, drawer, valuesStore, Tag, Divider, Space,false) }><i className='fas fa-eye' /></Button>
                    </Tooltip>}
                    {btnPermissions?.review_manual_txn && <Tooltip title="Review">
                        <Button className='btn-warning border-0' onClick={e => changeTxnStatus(record, 'review', 'single')}><i className='fas fa-check' /></Button>
                    </Tooltip>}
                    {btnPermissions?.approve_manual_txn && <Tooltip title="Approve">
                        <Button className='btn-success border-0' onClick={e => changeTxnStatus(record, 'approve', 'single')}><i className='fas fa-check-double' /></Button>
                    </Tooltip>}
                    {btnPermissions?.decline_manual_txn && <Tooltip title="Decline">
                        <Button className='btn-danger border-0' onClick={e => changeTxnStatus(record, 'decline', 'single')}><i className='fas fa-times' /></Button>
                    </Tooltip>}
                </Space>
            },
        },
    ]);

    

    async function setTableDataURL() {
        //the permissions are arranged from max to min. Max perms have the highest priority
        const permList = [
            'get_all_manual_txn',
            'get_approve_manual_txn',
            'get_review_manual_txn',
            'get_entry_manual_txn'
        ];
        for (let i = 0; i < permList.length; i++) {
            const v = permList[i];
            const hasPerm = await utils.hasPermission(v, null, valuesStore);
            if (hasPerm) {
                // console.log(v);                
                table.setDataURL(`${Settings.backend}/${v}`);
                break;
            }
        }
    }

    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {
            const permList = ['view_manual_txn_details',
                'review_manual_txn',
                'approve_manual_txn',
                'decline_manual_txn',
                'review_all_manual_txn',
                'approve_all_manual_txn',
                'decline_all_manual_txn'];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setBtnPermissions(r => ({ ...r, [v]: perm }))
            });
            table.rowSelectionDebug();
        }
    }

    const colFilters = [
        {
            filter: 'pay_item',
            sql: "SELECT item,alias FROM pay_items",
            key: 'alias',
            value: 'item'
        }
    ];

    

    useMemo(() => {
        setTableDataURL();
        showBtnUsingPermissions();
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        table.setAllowSelection(true);
        console.log('looping')
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams, btnPermissions]);

    function changeTxnStatus(record, status, type) {
        switch (status) {
            case "review": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeTxnStatus(table.selectedRows, 'review', 'id');
                    } else {
                        message.error('No selection was done');
                    }
                } else {
                    CustomFunctions.changeTxnStatus([record], 'review', 'id');
                }

                break;
            }
            case "approve": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeTxnStatus(table.selectedRows, 'approve', 'id');
                    } else {
                        message.error('No selection was done');
                    }
                } else {
                    CustomFunctions.changeTxnStatus([record], 'approve', 'id');
                }
                break;
            }
            case "decline": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeTxnStatus(table.selectedRows, 'decline', 'id');
                    } else {
                        message.error('No selection was done');
                    }
                } else {
                    CustomFunctions.changeTxnStatus([record], 'decline', 'id');
                }
                break;
            }
        }
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            {btnPermissions?.review_all_manual_txn && <Button onClick={e => changeTxnStatus(null, 'review', 'multiple')} className='btn-warning border-0'> <i className='fas fa-check me-2' /> Review All</Button>}
                            {btnPermissions?.approve_all_manual_txn && <Button onClick={e => changeTxnStatus(null, 'approve', 'multiple')} className='btn-success border-0'><i className='fas fa-check-double me-2' /> Approve All</Button>}
                            {btnPermissions?.decline_all_manual_txn && <Button onClick={e => changeTxnStatus(null, 'decline', 'multiple')} className='btn-danger border-0'><i className='fas fa-times me-2' /> Decline All</Button>}
                            <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} />
                            <Button className='btn-success border-0' onClick={e => utils.filterByDate('date_inserted', dateRange, table)}><i className='fas fa-filter me-2' />Filter</Button>
                        </Space>
                    </div>
                    <div className='col-md-12'>
                        {table.table}
                    </div>
                </div>
            </div>
            {drawer.drawerJSX()}
        </>
    );
}

export default memo(ProcessEntries);