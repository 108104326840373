
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const IndexNoStarter = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();        
    const keyOverrides = { categoryAlias: 'category' };
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage:true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'cert_type_index_starter', fields: ['*'] });


    const columns = ([
        {
            title: 'Type',
            dataIndex: 'type',
            ...table.getColumnSearchProps('type'),
        },
        {
            title: 'Number',
            dataIndex: 'number',
            ...table.getColumnSearchProps('number'),            
        },
        {
            title: 'Post-Dip. Begin Number',
            dataIndex: 'post_dip_number',
            ...table.getColumnSearchProps('post_dip_number'),
        },
        {
            title: 'Post-Dip.Entry Levels',
            dataIndex: 'post_dip_entry_levels',
            ...table.getColumnSearchProps('post_dip_entry_levels'),
        },                
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => editRecord(record, 'cert_type_index_starter')}><i className='fas fa-edit text-successx' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'cert_type_index_starter', where: 'id', whereType: 'closed' },
                     <Button className='btn-danger border-0'>  <i className='fas fa-trash text-dangerx' /></Button> 
                    )}
                </Space>
            },
        },
    ]);

    const colFilters = [
        // {
        //     filter: 'name',
        //     sql: "SELECT name,alias FROM reg_dist WHERE type_alias ='district' ",
        //     key: 'alias',
        //     value: 'name'
        // },
        // {
        //     filter: 'real_name',
        //     sql: 'SELECT name,real_name FROM package',
        //     key: 'name',
        //     value: 'real_name'
        // }
    ];

    function editRecord(record, tableName) {
        utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'cert_type_index_starter') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'cert_type_index_starter',
            { tbl: 'cert_type_index_starter', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'cert_type_index_starter' })
    }


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                            {/* <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} /> */}
                            {/* <Button onClick={e => utils.filterByDate('date_inserted', dateRange, table)}><i className='fas fa-filter me-2' />Filter</Button> */}
                        </Space>
                    </div>
                    <div className='col-md-8'>
                        {table.table}
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
        </>
    );
}

export default memo(IndexNoStarter);