
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import utils from '../../dependencies/custom/react-utilities';
import { Card, Statistic, Space, Button } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import useTable from '../../hooks/table';
import Settings from '../../dependencies/custom/settings';
import useBarChart from '../../hooks/barchart';
import useExcel from '../../hooks/excel';

const Admin = (props) => {
    const valuesStore = ValuesStore();
    const [admitted, setAdmitted] = useState();
    const [received, setReceived] = useState();
    const [paid, setPaid] = useState();
    const { filters, filterTypes } = utils.generateTableFilters();
    const barchartBanks = useBarChart();
    const barchartEntryModes = useBarChart();
    const excel = useExcel();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 20,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        }, null, null, null, null, 'id', null, null);


    const columns = ([
        {
            title: 'Bank',
            dataIndex: 'bank',
        },
        {
            title: 'Direct',
            dataIndex: 'direct',
        },
        {
            title: 'PostDip.',
            dataIndex: 'postdip',
        },
        {
            title: 'Mature',
            dataIndex: 'mature',
        },
        {
            title: 'PostGrad.',
            dataIndex: 'postgrad',
        },
        {
            title: 'IntlDirect',
            dataIndex: 'intldirect',
        },
        {
            title: 'IntlPostDip.',
            dataIndex: 'intlpd',
        },
        {
            title: 'IntlMature',
            dataIndex: 'intlmat',
        },
        {
            title: 'IntlPostGrad.',
            dataIndex: 'intlpg',
        },
        {
            title: 'CBT',
            dataIndex: 'cbt'
        },
        {
            title: 'Total',
            dataIndex: 'total',
            render: (v, record) => {
                return <label className='fw-bold purple-text'>{v}</label>
            }
        }
    ]);


    useMemo(async () => {
        table.setColumns(columns);
        // console.log(acadYear);
        getVoucherReport();
        getNumAdmitted(0);
        getNumReceived(0);
        getNumPaid(0);
    }, [valuesStore['settings']]);

    function card(color, title, titleClasses, value, precision = 0, valueColor, prefix, suffix = '') {
        return <div className='col'>
            <Card className={`${color} p-2`}>
                <Statistic
                    title={<label className={titleClasses}>{title}</label>}
                    value={value}
                    precision={precision}
                    valueStyle={{
                        color: valueColor,
                    }}
                    prefix={prefix}
                    suffix={suffix}
                />
            </Card>
        </div>;
    }

    async function getVoucherReport() {
        const url = valuesStore.getArrayObjectsValue('settings', 'prop', 'voucherReportUrl');
        if (!url || Object.keys(url) == 0) {
            return;
        }
        const ay = valuesStore?.getArrayObjectsValue('settings', 'prop', 'acad_year');
        let res = await utils.requestWithReauth('post', url?.value, null, { acadYear: ay.value });
        let data = res.res;
        let bgd = bankGraphData(data);
        data = data.map((v) => {
            const bank = v.bank;
            const total = bgd[bank];
            return { ...v, total }
        });
        let emgd = entryModeGraphData(data);
        emgd['bank'] = 'Total';
        table.setData([...data, emgd]);
        const newEmgd = { ...emgd };
        delete newEmgd['id'];
        delete newEmgd['bank'];
        delete newEmgd['total'];
        setChart(barchartBanks, bgd, 'Sale of Voucher Distribution according Banks', 'rgba(0, 150, 136, 0.7)');
        setChart(barchartEntryModes, newEmgd, 'Sale of Voucher Distribution according Entry Mode', 'rgba(205, 220, 57, 0.7)');
    }


    function setChart(barChart, dataToPlot, chartTitle, bgColor = 'rgba(255, 99, 132, 0.5)') {
        // const d = Object.entries(dataToPlot);
        let chartLabels = []
        let chartData = [];
        for (let key in dataToPlot) {
            chartLabels.push(key);
            chartData.push(dataToPlot[key]);
        }
        const data = {
            labels: chartLabels,
            datasets: [
                {
                    label: chartTitle,
                    data: chartData,
                    backgroundColor: bgColor,
                }
            ],
        };
        barChart.setData(data);
        barChart.setPlugins([barChart.allPlugins.ChartDataLabels]);
    }


    function bankGraphData(data) {
        let banks = {};
        data?.map(v => {
            banks[v.bank] = 0;
            for (let key in v) {
                if (!isNaN(v[key]) && key !== 'id') {
                    banks[v.bank] += parseInt(v[key]);
                }
            }
        });
        return banks;
    }

    function entryModeGraphData(data) {
        if (!data.length) return;
        let firstData = data[0];
        let keys = Object.keys(firstData);
        const entryModes = utils.arrayToObjectInitValuesToZero(keys);
        data?.map(v => {
            for (let key in v) {
                if (!isNaN(v[key])) {
                    entryModes[key] += parseInt(v[key]);
                }
            }
        });
        return entryModes;
    }


    async function getNumAdmitted(lastKnownNumber) {
        const url = valuesStore.getArrayObjectsValue('settings', 'prop', 'numAdmittedUrl');
        if (!url || Object.keys(url) == 0) return;
        const ay = valuesStore?.getArrayObjectsValue('settings', 'prop', 'acad_year');
        const res = await utils.requestWithReauth('post', url?.value, null, { acadYear: ay.value, lastKnownNumber });
        if (res.status == 'Ok') {
            setAdmitted(res.res);
            // getNumAdmitted(res.res);
        }
    }

    async function getNumReceived(lastKnownNumber) {
        const url = valuesStore.getArrayObjectsValue('settings', 'prop', 'numAppReceivedUrl');
        if (!url || Object.keys(url) == 0) return;
        const ay = valuesStore?.getArrayObjectsValue('settings', 'prop', 'acad_year');
        const res = await utils.requestWithReauth('post', url?.value, null, { acadYear: ay.value, lastKnownNumber });
        if (res.status == 'Ok') {
            setReceived(res.res);
            // getNumReceived(res.res);
        }
        // await getVoucherReport();//get fresh voucher report after application received changes
    }



    async function getNumPaid(lastKnownNumber) {
        const ay = valuesStore?.getArrayObjectsValue('settings', 'prop', 'acad_year');
        if (!ay || Object.keys(ay) == 0) return;
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_paid_applicants`, null, { acadYear: ay.value, lastKnownNumber });
        if (res.status == 'Ok') {
            setPaid(res.res);
            // getNumPaid(res.res);
        }
    }

    function exportXLS(ext) {
        const headings = [];
        const dataIndexes = [];
        columns.forEach(v => {
            headings.push(v.title);
            dataIndexes.push(v.dataIndex);
        });
        const data = table?.data?.map(v => {
            let obj = {};
            dataIndexes.forEach(key => {
                obj[key] = v[key];
            });
            return obj;
        });
        excel.exportXLSX(headings, data, 'sheet1', `voucher_report_${new Date()}.${ext}`);
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container'>
                <div className='row row-cols-4 mb-3'>
                    {card('blue-gradient', 'Applications Received', 'text-white fw-bold', received, undefined, '#fff', <ArrowUpOutlined />)}
                    {card('unique-colorx purple-gradient', 'Admitted Students', 'text-white fw-bold', admitted, undefined, '#fff', <ArrowUpOutlined />)}
                    {card('peach-gradient', 'Paid', 'text-white fw-bold', paid, undefined, '#fff', <ArrowUpOutlined />)}
                    {card('pink darken-4', '% Turn In', 'text-white fw-bold', Math.round(((paid / admitted) * 100)), undefined, '#fff', <ArrowUpOutlined />, '%')}
                </div>
                <div className='row mb-3'>
                    <div className='col-md-6'>{barchartBanks.BarChart()}</div>
                    <div className='col-md-6'>{barchartEntryModes.BarChart()}</div>
                </div>
                <div className='row row-cols-12'>
                    <label className='text-danger fw-bold'>Sold & Used Vouchers <a onClick={ e=>exportXLS('xlsx')}>[ <i className='fas fa-file'/> Click to Download]</a></label>

                    {table.table}
                </div>
            </div>
        </>
    );
}

export default memo(Admin);