
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, message } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const BankAccounts = (props) => {
    const valuesStore = ValuesStore();
    // const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    // const edit = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    const [modalTitle, setModalTitle] = useState('Add New Item');
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'bank_accounts', fields: ['*'] });


    const columns = ([

        {
            title: 'Bank',
            dataIndex: 'bank_name',
            ...table.getColumnSearchProps('bank_name'),
            // render: (v, record) => {
            //     console.log(record)
            //     return <a className='blue-text' onClick={e => navigate(`./users?email=${record['owner']}#page=1`)}>{utils.truncateText(v, 8)}</a>
            // }
        },
        {
            title: 'AccountNo.',
            dataIndex: 'account_no',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            render: (_, record) => {
                return utils.formatDate(_);
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => editRecord(record, 'bank_accounts')}><i className='fas fa-edit text-successx' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'bank_accounts', where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0'><i className='fas fa-trash text-dangerx' /></Button>, undefined, undefined, undefined, afterDelete
                    )}
                </Space>
            },
        },
    ]);

    async function afterDelete(status, msg, data) {        
        const rc = valuesStore.getArrayObjectsValue('settings', 'prop', 'bankDelAtAuthUrl');        
        const res = await utils.requestWithReauth('post',rc?.value,null,data);
        if(res.status =='Ok'){
            message.success('Bank has been deleted from authentication system');
        }else{
            message.error('Bank could not be deleted from authentication system');
        }        
    }

    const colFilters = [
        // {
        //     filter: 'name',
        //     sql: "SELECT name,alias FROM reg_dist WHERE type_alias ='district' ",
        //     key: 'alias',
        //     value: 'name'
        // },
        // {
        //     filter: 'real_name',
        //     sql: 'SELECT name,real_name FROM package',
        //     key: 'name',
        //     value: 'real_name'
        // }
    ];

    async function editRecord(record, tableName) {
        setModalTitle('Edit Item');        
        const rc = valuesStore.getArrayObjectsValue('settings', 'prop', 'getBankDetailsFromAuth');
        const res = await utils.requestWithReauth('post', rc?.value, null, record);                
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
        if (res.status == 'Ok') {
            const result = res.results[0];
            record['block'] = result?.block?.toString();
            record['bank_client_id'] = result?.client_id;
            record['email'] = result?.email;   
            record['is_live'] = record?.is_live?.toString();         
        }
        add.setRecord({ ...record });
    }

    function addRecord(tableName = 'bank_accounts') {
        setModalTitle('Add New Item');
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }


    async function addOnOk() {        
        const rc = valuesStore.getArrayObjectsValue('settings', 'prop', 'bankSaveAtAuthUrl');        
        let saveToAuth = await utils.requestWithReauth('post', rc?.value, null, add.record);        
        if (saveToAuth.status == 'Ok') {
            let res = await utils.requestWithReauth('post', `${Settings.backend}/save_bank_account_details`, null, add.record)
            if (res.status == 'Ok') {
                message.success('Operation successful');
                add.reset();
            } else {
                message.error(res.msg);
            }
        } else {
            message.error(saveToAuth.details);
        }
    }


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                            <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} />
                            <Button className='btn-success border-0' onClick={e => utils.filterByDate('date_inserted', dateRange, table)}><i className='fas fa-filter me-2' />Filter</Button>
                        </Space>
                    </div>
                    <div className='col-md-12  bg-whitex'>
                        {table.table}
                    </div>
                </div>
            </div>
            {add.addModal(modalTitle, addOnOk)}
        </>
    );
}

export default memo(BankAccounts);