
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Typography, Form, Input, Divider, message, Tooltip, Select } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDynamicForm from '../../hooks/dynamic_form';


const BillTemplates = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();

    const [templateUpdated, setTemplateUpdated] = useState(false);
    // const navigate = useNavigate();
    const formName = 'bill_template_form';
    const dynamicForm = useDynamicForm(formName, null, { type: 'primary', text: 'Submit' });

    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight']
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'student_bill_templates', fields: ['*'] });


    const columns = ([
        {
            title: 'Name',
            dataIndex: 'name',
            ...table.getColumnSearchProps('name'),
        },
        {
            title: 'Alias',
            dataIndex: 'alias',
            ...table.getColumnSearchProps('alias'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            ...table.getColumnSearchProps('description'),
        },
        // {
        //     title: 'Fees',
        //     key: 'fee',
        //     render: (record, rowIndex) => {
        //         console.log(record);
        //         return 3;
        //     },
        //     // ...table.getColumnSearchProps('description'),
        // },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => editRecord(record, 'student_bill_templates')}><i className='fas fa-edit text-successx' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'student_bill_templates', where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0'><i className='fas fa-trash text-dangerx' /></Button>
                    )}
                </Space>
            },
        },
    ]);


    async function editRecord(record) {
        localStorage.setItem('recalledTemplate', JSON.stringify(record));
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_bill_template_items_to_edit`, null, record);
        let total = 0;
        let data = res.results?.map(v => {
            total += parseFloat(v.fee);
            return [v.component, v.fee, v.priority,
            <Space>
                {/* <Tooltip title="Update template item" placement='bottom'>
                    <Button onClick={1}><i className='fas fa-edit text-success' /></Button>
                </Tooltip> */}
                {del.confirm(
                    `${Settings.backend}/delete`,
                    v,
                    'Are you sure to delete this item',
                    { tableName: 'student_bill_breakdown', where: 'id', whereType: 'closed' },
                    <Tooltip title="Delete template item" placement='bottom'>
                        <Button><i className='fas fa-trash text-danger' /></Button>
                    </Tooltip>
                    , undefined, undefined, undefined, delCallback)
                }
            </Space>
            ];
        });
        data.unshift([<label className='fw-bold text-danger'>Total</label>,<label className='fw-bold text-danger'>{total}</label>,'','']);
        data.push([<label className='fw-bold text-danger'>Total</label>,<label className='fw-bold text-danger'>{total}</label>,'','']);
        const details = utils.getTable(
            [
                <label>Component</label>,
                <label>Fee</label>,
                <label>Priority</label>,
                <label>Action</label>
            ],
            data, 'w-100 table table-sm table-striped', '', '', '');
        dynamicForm.setChildrenBottom(details);
        setTemplate();
        dynamicForm.form.setFieldsValue({
            template_name: record.name,
            template_alias: record.alias,
            template_description: record.description
        });
    }//end of function

    function delCallback(status) {
        if (status) {
            editRecord(JSON.parse(localStorage.getItem('recalledTemplate')));
        }
    }

    function setFormFields() {
        const components = valuesStore.getValue('student_bill_components');
        const componentOptions = components?.map(v => ({ value: v.component_name, label: v.component_name }));
        const formData = [
            { name: 'component', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter component name', inputType: 'select', options: componentOptions, width: 190 },
            { name: 'fee', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter fee', inputType: 'number', width: 100 },
            { name: 'priority', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter priority', inputType: 'number', width: 70 }
        ];
        dynamicForm.setFormFieldsToCreate(formData);
    }

    function setTemplate() {
        dynamicForm.setFormChildren(
            <div className='d-flex flex-column w-100'>
                <Form.Item
                    key={'template_name'}
                    name={'template_name'}
                    label={'Template Name'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>
                    <Input placeholder='Name' />
                </Form.Item>
                <Form.Item
                    key={'template_alias'}
                    name={'template_alias'}
                    label={'Alias'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>
                    <Input placeholder='Alias' />
                </Form.Item>
                <Form.Item
                    key={'template_description'}
                    name={'template_description'}
                    label={'Description'}
                >
                    <Input.TextArea placeholder="Description " autoSize />
                </Form.Item>
                <Divider orientation='middle'>Items</Divider>
            </div>
        );
        setFormFields();
        dynamicForm.setShowModal(true);
        dynamicForm.form.resetFields();
    }

    function submitTemplate() {
        dynamicForm.setFormSubmit({
            async onFormSubmit(values) {
                let res = await utils.requestWithReauth('post', `${Settings.backend}/save_bill_template`, null, values);
                if (res.status === 'Ok') {
                    message.success('Template has been created successfully');
                    editRecord(JSON.parse(localStorage.getItem('recalledTemplate')));
                    //for forcing rerendering
                    setTemplateUpdated(!templateUpdated);
                } else {
                    message.error(res.msg);
                }
            }
        });
    }

    function addNewBillTemplated() {
        dynamicForm.setChildrenBottom(undefined);
        setTemplate();
    }

    useMemo(() => {
        table.setColumns(columns);        
        console.log('looping')
        table.fetchData();
        submitTemplate();
        computeTotalFees(dynamicForm?.allValues?.[formName]);
    }, [templateUpdated, dynamicForm.currentChangedValue, add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);

    function computeTotalFees(formValues) {
        const total = formValues?.reduce((prev, curr) => {
            if (curr) {
                if (curr.fee) {
                    return prev + parseFloat(curr.fee);
                }
            }
        }, 0);
        dynamicForm.setChildrenTop(<Typography>
            <pre className='text-danger fw-bold'>Total Computed Fees (GHC): {total}</pre>
        </Typography>)
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            <Button className='btn-primary border-0' onClick={e => addNewBillTemplated()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                        </Space>
                    </div>
                    <div className='col-md-12'>
                        {table.tableWithHeaderFooter()}
                    </div>
                </div>
            </div>
            {dynamicForm.formModal('Bill Template', undefined, undefined, undefined, 700)}
        </>
    );
}

export default memo(BillTemplates);