
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import { Space, Button, Input, Select, Checkbox, Alert, Tag, Tooltip, Divider, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useTable from '../../hooks/table';
import useDrawer from '../../hooks/drawer';
import ReactToPrint, { useReactToPrint } from 'react-to-print';


const ManualEntry = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [btnPermissions, setBtnPermissions] = useState({});
    const [indexNumber, setIndexNumber] = useState();
    const [exemptIndexNo, setExemptIndexNo] = useState(false);
    const [amtToPayDisabled, setAmtToPayDisabled] = useState(true);
    const [amtToPay, setAmtToPay] = useState('');
    const [payerName, setPayerName] = useState('');
    const [payerContact, setPayerContact] = useState('');
    const [paymentType, setPaymentType] = useState();
    const [description, setDescription] = useState('');
    const [studentFound, setStudentFound] = useState(false);
    const [studentInfoBanner, setStudentInfoBanner] = useState();
    const [alertMsg, setAlertMsg] = useState("Student details will appear here");
    const navigate = useNavigate();
    const drawer = useDrawer();
    const [showReceipt, setShowReceipt] = useState(false);
    const [receipt, setReceipt] = useState(false);
    const printable = useRef();
    const [chequeNo, setChequeNo] = useState();

    const [schoolDetails, setSchoolDetails] = useState(
        {
            name: 'AKENTEN APPIAH-MENKAH UNIVERSITY OF SKILLS TRAINING AND ENTREPRENEURIAL DEVELOPMENT',
            office: 'Finance Directorate',
            pobox: 'P. O. Box 1277, Kumasi - Ghana',
            email: 'Email: finance@aamusted.edu.gh',
            website: 'Website: aamusted.edu.gh'
        }
    );

    function generateReceipt(details, student, staff) {
        const payItemRealName = valuesStore.getArrayObjectsValue('pay_items', 'alias', details?.pay_item);
        const jsx =
            <div ref={printable} className='container'>
                <section className='d-flex border-bottom'>
                    <div className='border-end'>
                        <img src={`${Settings.logo}/resources/sysimg/fav.png`} width={120} height={90} />
                    </div>
                    <div className='w-100'>
                        <center className='d-flex flex-column'>
                            <label className='h6 ms-2'>{schoolDetails.name}</label>
                            <label className='h6' style={{ marginTop: '-0.5rem' }}>{schoolDetails.office}</label>
                            <label className='h6' style={{ marginTop: '-0.5rem' }}>{schoolDetails.pobox}</label>
                        </center>
                        <div className='d-flex justify-content-around px-5' style={{ marginTop: '-0.5rem' }}>
                            <label className='h6'>{schoolDetails.email}</label>
                            <label className='h6'>{schoolDetails.website}</label>
                        </div>
                    </div>
                </section>
                <section className='row row-cols-2 fw-bold'>
                    <label>TxnID: {details?.bank_txn_id}</label>
                    <label>Date: {details?.date_inserted}</label>
                    <label>Student Name: {`${student?.fname || ''} ${student?.lname || ''}  ${student?.manme || ''} `}</label>
                    <label>Student ID: {details?.index_no || 'N/A'}</label>
                    <label>Payer Name: {details?.payer_name || 'N/A'}</label>
                    <label>Payer Contact: {details?.payer_contact || 'N/A'}</label>
                </section>
                <section className='border-topx border-bottom'>
                    {utils.getTable(['SN', 'Detail', 'Price (GHC)'], [[1, payItemRealName?.item || 'N/a', details?.amt_paid || 'N/A']], 'w-100', 'fw-bold', 'trClasses', 'fw-bold')}
                </section>
                <Space className='rowx row-cols-2x mt-4 fw-bold' wrap>
                    <label>Teller name: {staff?.name || 'N/A'}</label>
                    <label>Teller signature:....................</label>
                    <label>Payer signature:...........................................</label>
                </Space>
            </div>
        return jsx;
    }

    async function printRecpt(details) {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { indexNumber: details.index_no });
        const student = res?.result[0];
        const res1 = await utils.requestWithReauth('post', `${Settings.backend}/get_staff`, null, { staffID: details.teller });
        const staff = res1?.result[0];
        const d = generateReceipt(details, student, staff);
        setReceipt(d);
        setShowReceipt(true);
    }

    const payItems = 'pay_items_select';
    const payItemsSelectHook = useAdd(payItems, 'table_name');
    function setupPayItems() {
        setTimeout(e => {
            valuesStore.setValue(payItems, [
                {
                    id: '', table_name: 'manual_entry',
                    column_name: 'pay_item', col_real_name: 'Pay Item', type: 'sqlSelect',
                    options: '{"sql":"SELECT alias,item FROM pay_items","key":"alias","value":"item"}',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                }
            ]);
            payItemsSelectHook.setTblName('manual_entry');
        }, 1000);
    }

    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        // `${Settings.backend}/get_data`,
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'manual_txn', fields: ['*'] });


    const columns = ([
        {
            title: 'TxnID',
            dataIndex: 'bank_txn_id',
            ...table.getColumnSearchProps('bank_txn_id')
        },
        {
            title: 'AmtPaid',
            dataIndex: 'amt_paid'
        },
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no')
        },
        {
            title: 'Teller',
            dataIndex: 'teller',
            ...table.getColumnSearchProps('teller')
        },
        {
            title: 'Flow',
            dataIndex: 'debit_credit',
            render: (v, record) => {
                return <Tag
                    color={v == 'dr' ? '#2db7f5' : 'geekblue-inverse'}
                >{v.toUpperCase()}</Tag>

            },
            filters: [
                {
                    value: 'dr',
                    text: 'Debit'
                },
                {
                    value: 'cr',
                    text: 'Credit'
                }
            ]
        },
        {
            title: 'PayItem',
            dataIndex: 'pay_item',
            filterSearch: true
        },
        {
            title: 'Rev.',
            dataIndex: 'is_reversal',
            render: (v, record) => {
                return <Tag
                    color={v ? 'grey' : '#00695c'}
                >{v ? 'Y' : 'N'}</Tag>
            },
            filters: [
                {
                    value: '0',
                    text: 'Not Reversal'
                },
                {
                    value: '1',
                    text: 'Reversal'
                }
            ]
        },
        {
            title: 'Dec.',
            dataIndex: 'declined',
            render: (v, record) => {
                return <Tag
                    color={v ? 'red' : 'grey'}
                >{v ? 'Y' : 'N'}</Tag>
            },
            filters: [
                {
                    value: '0',
                    text: 'Not Declined'
                },
                {
                    value: '1',
                    text: 'Declined'
                }
            ]
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return new Date(utils.formatDate(v)).toLocaleDateString();
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    {btnPermissions?.view_manual_txn_details && <Tooltip title="View Details">
                        <Button className='btn-primary border-0' onClick={e => CustomFunctions.viewTxn(record, drawer, valuesStore, Tag, Divider, Space, false)}><i className='fas fa-eye' /></Button>
                    </Tooltip>}

                    {btnPermissions?.print_receipt && <Tooltip title="Print Preview">
                        <Button className='btn-secondary border-0' onClick={e => printRecpt(record)}><i className='fas fa-print' /></Button>
                    </Tooltip>}

                    {btnPermissions?.print_receipt && <Tooltip title="Print Receipt">
                        <ReactToPrint
                            trigger={() => <Button className='btn-warning border-0' onClick={e => printRecpt(record)}><i className='fas fa-print' /></Button>}
                            content={() => printable.current}
                        />
                    </Tooltip>}

                </Space>
            },
        },
    ]);

    const colFilters = [
        {
            filter: 'pay_item',
            sql: "SELECT item,alias FROM pay_items",
            key: 'alias',
            value: 'item'
        }
    ];

    useMemo(() => {
        setTableDataURL();
        showBtnUsingPermissions();
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        table.setAllowSelection(true);
        setupPayItems();
        enableAmtToPay();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, payItemsSelectHook.record, table.extraFetchParams, btnPermissions]);


    // viewTxn(record)
    // changeTxnStatus(record, 'review', 'single')
    async function setTableDataURL() {
        //the permissions are arranged from max to min. Max perms have the highest priority
        const permList = [
            'get_all_manual_txn',
            'get_approve_manual_txn',
            'get_review_manual_txn',
            'get_entry_manual_txn'
        ];
        for (let i = 0; i < permList.length; i++) {
            const v = permList[i];
            const hasPerm = await utils.hasPermission(v, null, valuesStore);
            if (hasPerm) {
                // console.log(v);                
                table.setDataURL(`${Settings.backend}/${v}`);
                break;
            }
        }
    }

    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {
            const permList = [
                'view_manual_txn_details',
                'print_receipt',
                'submit_credit_txn',
                'submit_debit_txn',
            ];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setBtnPermissions(r => ({ ...r, [v]: perm }))
            });
            table.rowSelectionDebug();
        }
    }

    function enableAmtToPay() {
        const payItem = payItemsSelectHook.record?.pay_item;
        const pi = valuesStore.getArrayObjectsValue('pay_items', 'alias', payItem);
        setAmtToPay('');
        if (pi?.price && pi?.price > 0) {
            setAmtToPayDisabled(true);
        } else {
            setAmtToPayDisabled(false);
        }
    }

    function changeIndexNoActiveState() {
        setExemptIndexNo(!exemptIndexNo);
        setIndexNumber('');
        setAmtToPay('');
    }

    async function getStudentDetails() {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { indexNumber });
        if (res?.result?.length) {
            setStudentFound(true);
            studentDetails(res?.result[0]);
        } else {
            setAlertMsg('Student not found');
            setStudentFound(false);
            changeIndexNoActiveState();
        }
    }

    function studentDetails(studentInfo) {
        const details = <div className="container">
            <div className="row row-cols-3 p-2 blue lighten-5 rounded">
                <div className="col">
                    First Name: {studentInfo?.fname}<br />
                    Last Name: {studentInfo?.lname}<br />
                    Middle Name: {studentInfo?.mname}<br />
                </div>
                <div className="col">
                    Index Number: {studentInfo?.index_no}<br />
                    Program: {studentInfo?.program_name}<br />
                    Level: {studentInfo?.current_level}
                </div>
                <div className="col">
                    Session: {studentInfo?.session}<br />
                    Current Semester :{studentInfo?.current_sem}
                </div>
            </div>
        </div>;
        setStudentInfoBanner(details);
    }

    async function submitEntry(flow) {
        const payItem = payItemsSelectHook.record?.pay_item;
        const record = { indexNumber, amtToPay, payerName, payerContact, paymentType, description, payItem, flow, chequeNo };
        const res = await utils.requestWithReauth('post', `${Settings.backend}/manual_entry`, null, record);
        if (res.status == 'Ok') {
            message.success('Transaction has been recorded');
            printRecpt(res?.result[0]);
        } else {
            message.success(res?.msg);
        }

    }


    const printPrintable = useReactToPrint({
        content: () => printable.current,
    });

    return (
        <>
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div className='mb-2'>
                            {studentFound ? studentInfoBanner : <Alert message={alertMsg} type="info" showIcon />}
                        </div>

                        <div className='d-flex'>
                            <Input
                                addonBefore={<Checkbox checked={exemptIndexNo} onChange={e => changeIndexNoActiveState()}>Exempt Index Number/Applicant ID</Checkbox>}
                                disabled={exemptIndexNo}
                                type='text'
                                placeholder='Enter Index Number/Applicant ID'
                                value={indexNumber}
                                onChange={e => setIndexNumber(e.target.value)}
                                className='w-100 me-3'
                                size="large"
                                onPressEnter={e => getStudentDetails()} />
                            <Button size='large' type='primary' onClick={e => getStudentDetails()} > <i className='fas fa-search me-2' /> Fetch Student</Button>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        {payItemsSelectHook.form}
                    </div>
                    <div className='col-md-6'>
                        <Space direction='vertical' wrap className='w-100'>
                            <Input type='number' disabled={amtToPayDisabled} addonBefore='Amount to Pay' value={amtToPay} onChange={e => setAmtToPay(e.target.value >= 0 ? e.target.value : 0)} />
                            <Input addonBefore='Payer name' value={payerName} onChange={e => setPayerName(e.target.value)} />
                            <Input addonBefore='Payer contact' value={payerContact} onChange={e => setPayerContact(e.target.value)} />
                        </Space>
                    </div>
                    <div className='col-md-6'>
                        <Space direction='vertical' wrap className='w-100'>
                            <div className={`input-group input-group-sm d-flex`}>
                                <span className="input-group-text">Payment Type</span>
                                <Select
                                    className='flex-grow-1'
                                    onChange={v => setPaymentType(v)}
                                    value={paymentType}
                                    options={[
                                        {
                                            value: 'cash',
                                            label: 'Cash',
                                        },
                                        {
                                            value: 'cheque',
                                            label: 'Cheque',
                                        },
                                        {
                                            value: 'momo',
                                            label: 'Momo',
                                        }
                                    ]}
                                />
                            </div>
                            <Input
                                placeholder="Cheque Number"
                                onChange={e => setChequeNo(e.target.value)}
                                value={chequeNo}
                            />
                            <Input.TextArea
                                placeholder="Description"
                                onChange={e => setDescription(e.target.value)}
                                value={description}
                                autoSize
                            />
                        </Space>
                    </div>
                </div>
                <Space className='mt-2'>
                    {btnPermissions?.submit_credit_txn && <Button onClick={e => submitEntry('cr')} className='btn-primary border-0' icon={<i className='fas fa-paper-plane me-2' />}>Credit Txn</Button>}
                    {btnPermissions?.submit_debit_txn && <Button onClick={e => submitEntry('dr')} className='btn-info border-0' icon={<i className='fas fa-paper-plane me-2' />}>Debit Txn</Button>}
                </Space>
            </div>
            <div className='row mt-2'>
                <div className='col-md-12'>
                    {table.table}
                </div>
            </div>
            <Modal title="Receipt of Payment" open={showReceipt} onOk={e => printPrintable()} onCancel={e => setShowReceipt(false)} width={720} okText={<><i className='fas fa-print me-1' />Print</>}>
                {receipt}
            </Modal>
            {drawer.drawerJSX()}
        </>
    );
}

export default memo(ManualEntry);